import { toast } from "react-hot-toast"

// Utility function to show loading toast with delay
export function showDelayedLoadingToast(
  delay: number,
  isMountedRef?: React.MutableRefObject<boolean>
) {
  const toastId = setTimeout(() => {
    if (isMountedRef?.current === true) {
      toast.loading("Still loading... Please wait", { id: "loadingToast" })
    }
  }, delay)

  // Return the toastId so it can be cleared later
  return toastId
}
