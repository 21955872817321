import { Link } from "react-router-dom"
import { type ReportButtonType } from "../data/reportspage"

interface Props {
  href: string
  className?: string
  value: ReportButtonType
}

export function ReportButton(props: Props) {
  const className = "w-full mx-auto px-1 mt-2 group"

  return (
    <Link className={className} to={props.href}>
      <div className="flex flex-col justify-between cursor-pointer sm:h-32 h-32 w-full items-center rounded-lg bg-[#14213D] group-hover:bg-[#FCA311] active:bg-[#FCA311] group-hover:text-white">
        <p className="font-bold text-center mt-2 text-md text-white">{props.value.title}</p>
        <div className="rounded-full mb-3">
          <img
            src={props.value.icon}
            alt={props.value.title}
            className="w-12 h-12 rounded-full"
          ></img>
        </div>
      </div>
    </Link>
  )
}
