import {
  type IBusiness,
  type ILocations,
  type ISummary,
} from "../components/Reports/Period/PeriodTypesFlattened"

interface Data {
  businesses: ILocations[]
  locations: any[]
}

export function flattenPeriods(data: Data): IBusiness[] {
  const businesses: IBusiness[] = []

  if (data?.businesses !== undefined) {
    for (const originalStore of data.businesses) {
      const businessId = originalStore.store.businessInfo.businessId
      const summary: ISummary = originalStore.summary

      // Copy of store with sorted terminals
      const store = {
        ...originalStore,
        store: {
          ...originalStore.store,
          terminals: [...originalStore.store.terminals].sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
          ),
        },
      }

      let business: IBusiness | undefined = businesses.find(
        (b) => b.businessInfo.businessId === businessId
      )

      if (business === undefined) {
        business = {
          businessInfo: {
            businessId: "",
            city: "",
            country: "",
            locality: "",
            name: "",
            phone: "",
            secondaryPhone: "",
            street: "",
            zipPostal: "",
          },
          stores: [],
          summaryTotals: {
            cds: 0,
            cdr: 0,
            cdn: 0,
            cws: 0,
            cwr: 0,
            cwn: 0,
            lws: 0,
            lwr: 0,
            lwn: 0,
            tms: 0,
            tmr: 0,
            tmn: 0,
            lms: 0,
            lmr: 0,
            lmn: 0,
          },
        }
        businesses.push(business)
      }

      business.stores.push(store)
      business.businessInfo = store.store.businessInfo

      for (const key in summary) {
        const summaryKey = key as keyof ISummary
        if (business.summaryTotals[summaryKey] === undefined) {
          business.summaryTotals[summaryKey] = 0
        }
        business.summaryTotals[summaryKey] += summary[summaryKey]
      }
    }

    // sort locations
    for (const business of businesses) {
      business.stores.sort((a, b) =>
        a.store.name.localeCompare(b.store.name, undefined, { numeric: true })
      )
    }

    businesses.sort((a, b) =>
      a.businessInfo.name.localeCompare(b.businessInfo.name, undefined, { numeric: true })
    )

    console.log("flattenPeriods", businesses)
    return businesses
  } else {
    return []
  }
}
