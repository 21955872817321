import Box from "@mui/material/Box"

interface SalesBoxStyledProps {
  children: React.ReactNode
  textAlign?: "left" | "right" // Added optional textAlign prop
  color?: string
  hideOnMobile?: boolean
}

export const SalesBoxStyled = ({
  children,
  textAlign = "right",
  hideOnMobile = false,
}: SalesBoxStyledProps) => (
  <Box
    sx={{
      flex: "1 1 auto",
      textAlign,
      minWidth: "40px",
      flexBasis: "20%",
      display: hideOnMobile ? { xs: "none", sm: "block" } : "block",
    }}
  >
    {children}
  </Box>
)
