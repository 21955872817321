import { useState } from "react"
import { styled, type Theme, type CSSObject } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { homeButtons } from "../data/homepage"
import { useUserContext } from "../context/UserContext"
import { useLocation, useNavigate } from "react-router-dom"
import Tooltip from "@mui/material/Tooltip"
import { ClickAwayListener } from "@mui/material"

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open === true && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(open === false && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

export function Sidebar() {
  const [open, setOpen] = useState(false)

  const data = useUserContext()

  const location = useLocation()

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerToggle = () => {
    open ? handleDrawerClose() : handleDrawerOpen()
  }

  const navigate = useNavigate()

  return (
    <>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className="flex">
            <h3 className="mr-24">Menu</h3>
            <IconButton onClick={handleDrawerToggle}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <List>
              {homeButtons(data.signOutHandler).map((value, index) => (
                <ListItem
                  onClick={() => {
                    value.onclick != null ? value.onclick() : navigate(value.href ?? "/")
                    handleDrawerClose()
                  }}
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  {open ? (
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor:
                          value.href !== "/" &&
                          value.href !== undefined &&
                          location.pathname.includes(value.href)
                            ? "primary.main"
                            : "transparent",
                        color:
                          value.href !== "/" &&
                          value.href !== undefined &&
                          location.pathname.includes(value.href)
                            ? "#fff"
                            : "#676767",
                        "&:hover": {
                          backgroundColor:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            location.pathname.includes(value.href)
                              ? "primary.main"
                              : "#f5f5f5",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            location.pathname.includes(value.href)
                              ? "#fff"
                              : "",
                        }}
                      >
                        <value.Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={value.title}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  ) : (
                    <Tooltip title={value.title} arrow placement="right">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          backgroundColor:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            location.pathname.includes(value.href)
                              ? "primary.main"
                              : "transparent",
                          color:
                            value.href !== "/" &&
                            value.href !== undefined &&
                            location.pathname.includes(value.href)
                              ? "#fff"
                              : "#676767",
                          "&:hover": {
                            backgroundColor:
                              value.href !== "/" &&
                              value.href !== undefined &&
                              location.pathname.includes(value.href)
                                ? "primary.main"
                                : "#f5f5f5",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              value.href !== "/" &&
                              value.href !== undefined &&
                              location.pathname.includes(value.href)
                                ? "#fff"
                                : "",
                          }}
                        >
                          <value.Icon />
                        </ListItemIcon>
                        <ListItemText
                          primary={value.title}
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </ClickAwayListener>
    </>
  )
}
