import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogContentText,
  Grid,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import customStyles from "../styles/tokens.json"
import { CustomDialogPrimaryButton } from "./CustomDialogPrimaryButton"
import { CustomLinearProgressButton } from "./CustomLinearProgressButton"

interface CustomDialogProps {
  open: boolean
  title: string
  content?: React.ReactNode
  dialogContentText?: string
  primaryActionText?: string
  secondaryActionText?: string
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
  handleClose?: () => void
  disabledPrimaryAction?: boolean
  disabledSecondaryAction?: boolean
  isLoading?: boolean
  disableHandleClose?: boolean
  fullScreen?: boolean
  centerTitle?: boolean
  hideCloseIcon?: boolean
  progressBarValue?: number
}

export const CustomDialog = ({
  open,
  title,
  content,
  dialogContentText,
  primaryActionText,
  secondaryActionText,
  onPrimaryAction,
  onSecondaryAction,
  disableHandleClose,
  handleClose,
  disabledPrimaryAction = false,
  disabledSecondaryAction = false,
  isLoading,
  fullScreen,
  centerTitle = false,
  hideCloseIcon = false,
  progressBarValue,
}: CustomDialogProps) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"))
  const isSmallScreen = useMediaQuery("(max-width:325px)")
  const direction = isSmallScreen ? "column" : "row"
  let isFullScreen = false
  if (isPhone) {
    isFullScreen = fullScreen !== undefined ? fullScreen : true
  }
  return (
    <Dialog open={open} fullWidth fullScreen={isFullScreen}>
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        disabled={disableHandleClose}
        sx={{
          alignSelf: "end",
          margin: "20px 24px 0 0",
          display: hideCloseIcon ? "none" : "block",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          padding: "15px",
        }}
      >
        <DialogTitle
          sx={{
            fontSize: isPhone ? "min(25px, calc(10px + 5vmin))" : "min(30px, calc(24px + 0.8vmin))",
            fontWeight: "bold",
            paddingTop: 0,
            paddingLeft: "8px",
            textAlign: centerTitle ? "center" : "left",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          {dialogContentText !== null && dialogContentText !== undefined && (
            <DialogContentText sx={{ fontSize: "min(20px, calc(12px + 0.8vmin))" }}>
              {dialogContentText}
            </DialogContentText>
          )}
          {content}
        </DialogContent>
        {primaryActionText !== null && primaryActionText !== undefined && (
          <DialogActions
            sx={{
              paddingRight: "8px",
            }}
          >
            <Grid
              container
              direction={direction}
              spacing={1}
              justifyContent={isSmallScreen ? "center" : "flex-end"}
              alignItems={isSmallScreen ? "center" : "flex-end"}
            >
              {secondaryActionText !== null && secondaryActionText !== undefined && (
                <Grid item sx={{ width: isSmallScreen ? "100%" : {} }}>
                  <Button
                    sx={{
                      fontSize: "min(16px, calc(10px + 0.8vmin))",
                      width: "100%",
                      bgcolor: customStyles.components.buttons.secondary.color,
                      ":hover": { bgcolor: customStyles.components.buttons.secondary.hoverColor },
                    }}
                    variant="contained"
                    disabled={disabledSecondaryAction}
                    onClick={onSecondaryAction}
                  >
                    {secondaryActionText}
                  </Button>
                </Grid>
              )}
              <Grid item sx={{ width: isSmallScreen ? "100%" : {} }}>
                {progressBarValue !== null && progressBarValue !== undefined ? (
                  <CustomLinearProgressButton
                    primaryActionText={primaryActionText}
                    onPrimaryAction={onPrimaryAction}
                    disabledPrimaryAction={disabledPrimaryAction}
                    progressBarValue={progressBarValue}
                  />
                ) : (
                  <CustomDialogPrimaryButton
                    primaryActionText={primaryActionText}
                    onPrimaryAction={onPrimaryAction}
                    disabledPrimaryAction={disabledPrimaryAction}
                    isLoading={isLoading}
                  />
                )}
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}
