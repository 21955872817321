/**
 * Constructs a list of terminal details and error messages for UI display.
 * It iterates through terminalDetailsFields, creating an entry for each with a formatted value.
 * Errors trigger an additional entry with the specific error message in the returned object,
 * except for 'status', where 'offline' directly shows an error icon.
 */

import type { TerminalDetails, ITerminalDetailsField } from "../types/detailsTypes"
import { formatBytes } from "./formatBytes"
import { formatDate } from "./formatDate"

interface DetailListItem {
  label: string
  value: string
  errorCondition: boolean
}

export function constructTerminalDetailsList(
  terminalDetails: TerminalDetails,
  terminalDetailsFields: ITerminalDetailsField[]
): DetailListItem[] {
  const listItems: DetailListItem[] = []

  terminalDetailsFields.forEach((field) => {
    const fieldValue = terminalDetails[field.key]
    const hasError = field.errorCondition != null ? field.errorCondition(terminalDetails) : false

    let value: string
    if (fieldValue == null || fieldValue === "") {
      value = "N/A"
    } else if (["RAM", "displayRAM", "totalStorage", "freeStorage"].includes(field.key)) {
      value = formatBytes(String(fieldValue))
    } else if (["statusDate", "bootDate", "registrationDate"].includes(field.key)) {
      value = formatDate(String(fieldValue))
    } else {
      value = String(fieldValue)
    }

    // Special handling for the 'status' field to directly mark it as error if offline
    if (field.key === "status" && terminalDetails.status === "offline") {
      listItems.push({
        label: field.label,
        value,
        errorCondition: true, // Directly highlight if 'status' is 'offline'
      })
    } else if (field.key === "generalStatus" && terminalDetails.generalStatus === -1) {
      listItems.push({
        label: field.label,
        value: "N/A",
        errorCondition: false,
      })
    } else {
      // Add the primary detail row without marking it as an error conditionally
      listItems.push({
        label: field.label,
        value,
        errorCondition: field.key === "status" ? hasError : false, // Error only for 'status', not peripherals
      })
    }

    // Handle error messages for peripherals separately
    if (
      hasError &&
      (field.errorMessageKey as keyof TerminalDetails) != null &&
      field.key !== "status" &&
      terminalDetails[field.statusCode as keyof TerminalDetails] !== -1
    ) {
      // Ensure 'status' is excluded from this logic
      const errorMessageKey = field.errorMessageKey as keyof TerminalDetails
      const errorMessageValue = terminalDetails[errorMessageKey]
      const hasErrorMessage =
        typeof errorMessageValue === "string" &&
        errorMessageValue !== "None" &&
        errorMessageValue.trim() !== ""
      const errorMessage = hasErrorMessage ? String(errorMessageValue) : "Error details unavailable"

      // Add a separate row for the error message with errorCondition set to true for peripherals
      listItems.push({
        label: `${field.label} Error`,
        value: errorMessage,
        errorCondition: true, // Mark only the error message row as having an error
      })
    }
  })

  return listItems
}
