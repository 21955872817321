import React from "react"
import { Alert } from "@mui/material"

import { type PeriodDataRoot, type IBusiness } from "./PeriodTypesFlattened"
import { BusinessCard } from "./PeriodBusinessCard"

export function PeriodBusinesses(report: PeriodDataRoot): React.ReactElement {
  if (report.periodData.length === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }
  return (
    <>
      {report.periodData.map((business: IBusiness) => {
        return (
          <React.Fragment key={business.businessInfo.businessId}>
            <BusinessCard business={business} />
          </React.Fragment>
        )
      })}
    </>
  )
}
