import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from "@mui/material"
import React from "react"

export const TerminalDetailsSkeleton = () => (
  <div className="w-full sm:max-w-3xl mb-20 mx-auto">
    <Typography component="div" textAlign="center" variant="h4">
      Terminal Details
    </Typography>
    <Card variant="outlined">
      <CardHeader
        title={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 1,
            }}
          >
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </Box>
        }
      />

      <CardContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: 1,
          }}
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <React.Fragment key={index}>
              <Typography variant="body2">
                <Skeleton variant="text" />
              </Typography>
              <Typography variant="body2">
                <Skeleton variant="text" />
              </Typography>
            </React.Fragment>
          ))}
        </Box>
      </CardContent>
    </Card>
  </div>
)
