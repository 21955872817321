import { Tooltip } from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"
import { getPaymentMethodColor } from "../utils/getPaymentMethodColor"
interface PurchaseMethodIconProps {
  purchaseMethod: string
}

export const PurchaseMethodIcon = ({ purchaseMethod }: PurchaseMethodIconProps) => {
  const { color } = getPaymentMethodColor(purchaseMethod)
  return (
    <Tooltip placement="left" title="Purchase Method">
      {purchaseMethod?.toLowerCase() === "invoice" ? (
        <ReceiptOutlinedIcon className="mr-1" fontSize="small" sx={{ color }} />
      ) : (
        <CreditCardIcon className="mr-1" fontSize="small" sx={{ color }} />
      )}
    </Tooltip>
  )
}
