import Typography from "@mui/material/Typography"

interface RevenueTypographyStyledProps {
  children: React.ReactNode
  variant?: "subtitle2"
  color?: string
}

export const RevenueTypographyStyled = ({
  children,
  variant,
  color,
}: RevenueTypographyStyledProps) => (
  <Typography
    variant={variant}
    color={color}
    sx={{
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      ...(variant === "subtitle2" && {
        fontSize: "0.75rem",
        fontWeight: 500,
      }),
    }}
  >
    {children}
  </Typography>
)
