import { type SvgIconProps } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

interface Props {
  children: string
  onClick?: (event: React.MouseEvent) => void
  href?: string
  className?: string
  icon: React.ReactNode
  iconProps?: SvgIconProps
}

export function HomeButton(props: Props) {
  // Determine if the button is "Account Settings" or "Sign Out"
  const className =
    "flex rounded p-2 w-full text-center mb-4 shadow cursor-pointer justify-start items-center select-none bg-[#14213D] hover:bg-[#FCA311] active:bg-[#FCA311]"

  const iconClass = "flex-shrink-0 ml-4"
  const textClass = "text-left flex-grow ml-4 text-white"

  const handleClick = (event: React.MouseEvent) => {
    props.onClick?.(event)
  }

  return props.href != null ? (
    <Link to={props.href} className={className} onClick={handleClick}>
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.children}</span>
    </Link>
  ) : (
    <div className={className} onClick={handleClick}>
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.children}</span>
    </div>
  )
}
