import { styled } from "@mui/material/styles"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"
import ListItemText from "@mui/material/ListItemText"
import IconButton, { type IconButtonProps } from "@mui/material/IconButton"
import Badge, { type BadgeProps } from "@mui/material/Badge"
import List from "@mui/material/List"

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    padding: "0 4px",
  },
}))

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "inherit",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const CardStyled = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  "&:first-of-type": { marginTop: 0 },
  "&:last-of-type": { marginBottom: theme.spacing(4) },
}))

export const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: "1px",
  backgroundColor: "#DEE2E6",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

export const CardContentStyled = styled(CardContent)({
  "&.MuiCardContent-root": {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    marginTop: 0,
  },
})

export const ListStyled = styled(List)({
  "&.MuiList-padding": {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
})
export const ListItemTextStyled = styled(ListItemText)({
  flex: "1 1 20%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  marginRight: "0.5rem",
  "&:last-child": {
    marginRight: 0,
  },
})
