export const getPaymentMethodColor = (method: string) => {
  const lowerCasedMethod = method?.toLowerCase()
  if (lowerCasedMethod === "invoice") {
    return { backgroundColor: "#932fed52", color: "#240c6e" }
  } else if (lowerCasedMethod === "credit" || lowerCasedMethod === "credit card") {
    return { backgroundColor: "#86ccb27d", color: "#144B23" }
  } else {
    return { backgroundColor: "transparent", color: "inherit" }
  }
}
