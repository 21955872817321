import React from "react"
import { formatAmount, formatAmountNoCents } from "../../../utils/formatAmount"
import { type CartItem } from "../../../types/cartItemTypes"
import { Typography } from "@mui/material"

interface MachineListProps {
  cartItems: CartItem[]
}

export const MachineList: React.FC<MachineListProps> = ({ cartItems }) => {
  return (
    <div className="max-h-96 overflow-y-auto">
      <div className="grid grid-cols-[26%_35%_25%_auto] bg-gray-300">
        <div className="font-bold text-base p-2">Terminal</div>
        <div className="font-bold text-base p-2 text-right">Fill</div>
        <div className="font-bold text-base p-2 text-right">Price</div>
        <div></div>
      </div>

      {cartItems.map((cartItem, index) => (
        <div
          key={index}
          className="grid grid-cols-[26%_35%_25%_auto] items-center border-b border-gray-300"
        >
          <div className="p-2">{cartItem.machineId}</div>
          <div className="p-2 text-right">
            {cartItem.amount === undefined || cartItem.amount === null
              ? formatAmountNoCents("")
              : formatAmountNoCents(cartItem.amount)}
          </div>
          <div className="p-2 text-right">
            <Typography>
              {cartItem.price === undefined || cartItem.price === null
                ? formatAmount(0).full
                : formatAmount(cartItem.price).full}
            </Typography>
          </div>
          <div className="p-2 text-right"></div>
        </div>
      ))}
    </div>
  )
}
