import { useEffect, useRef, useState } from "react"

import dayjs, { type Dayjs } from "dayjs"
import { activitySync } from "../utils/activitySync"

const useUserActivity = (
  inactivityBufferMilliseconds: number,
  onTimeout: (time: Dayjs) => void
) => {
  const [isInactive, setIsInactive] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  // last activity time in the current tab
  const lastActivityTimeRef = useRef<Dayjs>(dayjs())

  const resetTimer = () => {
    const currentTime = dayjs()
    lastActivityTimeRef.current = currentTime

    if (timerRef.current != null) {
      clearTimeout(timerRef.current)
    }
    setIsInactive(false)
    // Notify all tabs of activity
    activitySync.notifyActivity(currentTime)

    timerRef.current = setTimeout(() => {
      setIsInactive(true)
      onTimeout(lastActivityTimeRef.current)
    }, inactivityBufferMilliseconds)
  }

  useEffect(() => {
    const events = [
      "click",
      "keypress",
      "scroll",
      "mousemove",
      "touchstart",
      "touchmove",
      "pointerdown",
      "pointermove",
      "mouseup",
    ]
    const handleMouseDown = () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current)
      }
      setIsInactive(false)
    }

    const cleanup = activitySync.subscribe((message) => {
      if (message.type === "ACTIVITY_UPDATE" && typeof message.lastActivityTime === "string") {
        const activityTime = dayjs(message.lastActivityTime)
        lastActivityTimeRef.current = activityTime

        if (timerRef.current != null) {
          clearTimeout(timerRef.current)
        }

        setIsInactive(false)
        timerRef.current = setTimeout(() => {
          setIsInactive(true)
          onTimeout(lastActivityTimeRef.current)
        }, inactivityBufferMilliseconds)
      }
    })

    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })
    window.addEventListener("mousedown", handleMouseDown)
    resetTimer()

    return () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current)
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer)
      })
      window.removeEventListener("mousedown", handleMouseDown)
      cleanup()
    }
  }, [inactivityBufferMilliseconds, onTimeout])

  return { isInactive, resetTimer }
}
export default useUserActivity
