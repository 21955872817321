import { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resetFilters } from "../../../redux/filters/filters.slice"
import dayjs, { type Dayjs } from "dayjs"
import { useGetPeriodMutation } from "../../../redux/business/business.api"
import { PeriodBusinesses } from "../../../components/Reports/Period/PeriodBusinesses"
import { flattenPeriods } from "../../../middlewares/flattenPeriods"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { type HandleSnackBarType, LocationInputType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { PeriodSkeleton } from "../../../layouts/Skeletons/PeriodSkeleton"
import { CalendarInputType } from "../../../types/reportTypes"
import { type RootState } from "../../../redux/store"
import { useMediaQuery, useTheme } from "@mui/material"
import { periodDetailedConfig, periodSummaryConfig } from "../../../data/exportConfigs"
import { flattenExport } from "../../../utils/flattenExport"
import {
  type IBusiness,
  type ILocations,
} from "../../../components/Reports/Period/PeriodTypesFlattened"

interface Props {
  openFiltersHandler?: () => void
}

export function Period(props: Props) {
  const [fetchReport] = useGetPeriodMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = false
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)

  const dispatch = useDispatch()

  const isMountedRef = useRef(true)

  const [isFetchingReport, setIsFetchingReport] = useState(true)
  const allLocationsOption = { name: "All Locations", id: null }
  const [selectedValue, setSelectedValue] = useState(allLocationsOption)

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      dispatch(resetFilters())
    }
  }, [])

  useEffect(() => {
    const fetchReportOnMount = async () => {
      try {
        await getReportsHandler(
          fetchReport,
          setIsFetchingReport,
          handleSnackBar,
          setReport,
          location,
          hasHeaderDate,
          requiresAPICall,
          setShowHeaderDate,
          "data.data.storeList",
          {
            dateString: reportDateRange[0]?.startOf("day").format("YYYY-MM-DD"),
          },
          isMountedRef
        )
        setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
      } catch (error) {
        console.log(error)
      }
    }

    void fetchReportOnMount()
  }, [])

  const statusFilters = useSelector((state: RootState) => state.filters.statusFilters)
  const filterStores = (businesses: IBusiness[]): IBusiness[] => {
    const noStatusFiltersSelected = Object.values(statusFilters).every((value) => !value)

    if (noStatusFiltersSelected) {
      return businesses
    }

    return businesses
      .map((business) => {
        const filteredStores = business.stores.filter((store: ILocations) => {
          const statusFilterKey = Object.keys(statusFilters).find(
            (key) => key.toLowerCase() === store.store.statusMsg.toLowerCase()
          )

          let statusFilterValue = true
          if (statusFilterKey !== undefined) {
            statusFilterValue = statusFilters[statusFilterKey]
          }

          return noStatusFiltersSelected || statusFilterValue
        })

        return {
          ...business,
          stores: filteredStores,
        }
      })
      .filter((business) => business.stores.length > 0)
  }
  const flattenedReport = useMemo(() => {
    return flattenPeriods({
      businesses: report,
      locations: [],
    })
  }, [report])

  const filteredReport = filterStores(flattenedReport)
  useEffect(() => {
    const handleOrientationChange = () => {
      // We'll close the form on any orientation change if there's content in filteredReport.
      if (filteredReport.length > 0) {
        setShowForm(false)
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the filteredReport changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [filteredReport.length])

  useEffect(() => {
    if (isMobile && filteredReport.length !== 0) {
      toggleForm(false)
    }
  }, [isMobile, filteredReport.length, toggleForm])

  // Render calendar based on .env file

  let calendarInputParam, locationInputParam
  if (process.env.REACT_APP_RENDER_CALENDAR_PERIOD === "TRUE") {
    calendarInputParam = CalendarInputType.Date
    locationInputParam = LocationInputType.SelectGetLoad
  } else {
    calendarInputParam = CalendarInputType.NoDate
    locationInputParam = LocationInputType.AutoLoad
  }

  const exportFlattenedDetailedData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, periodDetailedConfig)
      : []
  const exportFlattenedSummaryData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, periodSummaryConfig)
      : []
  return (
    <ReportLayout
      hasExport={true}
      exportFlattenedDetailedData={exportFlattenedDetailedData}
      exportFlattenedSummaryData={exportFlattenedSummaryData}
      reportName={"Period"}
      calendarInput={calendarInputParam}
      locationInput={locationInputParam}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={[]}
      showAccordionHeadings={false}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      isFetchingReport={isFetchingReport}
      isActiveReport={Boolean(filteredReport?.length ?? 0)}
      disableChooseLocation={true}
      getReportHandler={async () => {
        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data.storeList",
            {
              dateString: reportDateRange[0]?.startOf("day").format("YYYY-MM-DD"),
            },
            isMountedRef
          )
          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <PeriodSkeleton />}
        {!isFetchingReport && report?.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && report?.length > 0 && (
          <PeriodBusinesses periodData={filteredReport} />
        )}
      </>
    </ReportLayout>
  )
}
