import { Typography, TableCell, Box, TableRow } from "@mui/material"
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import MoveDownIcon from "@mui/icons-material/MoveDown"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

export function renderTypeIcon(type: string) {
  if (type === "Add") {
    return (
      <Tooltip title="Add" placement="left" enterTouchDelay={0}>
        <AddIcon fontSize="medium" sx={{ fill: "#4caf50" }} />
      </Tooltip>
    )
  } else if (type === "Remove") {
    return (
      <Tooltip title="Remove" placement="left" enterTouchDelay={0}>
        <RemoveIcon fontSize="medium" sx={{ fill: "#f44336" }} />
      </Tooltip>
    )
  } else if (type === "Stack") {
    return (
      <Tooltip title="To Stacker" placement="left" enterTouchDelay={0}>
        <MoveDownIcon fontSize="medium" sx={{ fill: "#2196f3" }} />
      </Tooltip>
    )
  } else {
    return null
  }
}

export const SummarySection = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
})

export const SummaryItem = styled(Typography)({
  flex: "1 1 auto",
  flexDirection: "row-reverse",
  minWidth: "150px",
  textAlign: "center",
})

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: `1px solid rgba(128, 128, 128, .125)`,
  },
  "&:before": {
    display: "none",
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(128, 128, 128, .125)",
}))

export const FixedWidthTableCell = styled(TableCell)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  width: "150px", // Set the maximum width for the table cell
  overflow: "hidden", // Hide any content that exceeds the maximum width
  textOverflow: "ellipsis", // Show ellipsis (...) for the truncated text
  whiteSpace: "nowrap", // Keep the content on a single line
}))

export const TableCellPerformance = styled(TableCell)(({ theme }) => ({
  // paddingRight: theme.spacing(1),
  minWidth: "25%", // Match the minWidth in SummaryItem
  flexBasis: "25%", // Match the flexBasis in SummaryItem
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "right", // Assuming right alignment as in SummaryItem
}))

export const TableCellTruncatePerformance = styled(TableCell)(({ theme }) => ({
  // paddingRight: theme.spacing(0.5),
  minWidth: "25%", // Set the maximum width for the table cell
  flexBasis: "25%",
  overflow: "hidden", // Hide any content that exceeds the maximum width
  textOverflow: "ellipsis", // Show ellipsis (...) for the truncated text
  whiteSpace: "nowrap", // Keep the content on a single line
  textAlign: "left",
  "@media (max-width: 600px)": {
    width: "72px",
  },
}))

export const FixedWidthTableCellTruncate = styled(TableCell)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  width: "150px", // Set the maximum width for the table cell
  overflow: "hidden", // Hide any content that exceeds the maximum width
  textOverflow: "ellipsis", // Show ellipsis (...) for the truncated text
  whiteSpace: "nowrap", // Keep the content on a single line
  "@media (max-width: 600px)": {
    width: "72px",
  },
}))

export const FixedWidthTableCellValue = styled(TableCell)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
  width: "150px", // Set the maximum width for the table cell
  overflow: "hidden", // Hide any content that exceeds the maximum width
  textOverflow: "ellipsis", // Show ellipsis (...) for the truncated text
  whiteSpace: "nowrap", // Keep the content on a single line
  textAlign: "right",
}))

export const PeriodStyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  textAlign: "right",
  minWidth: 87,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: "bold",
  '&[data-mui-is="MuiTableCell-root"]': {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const PeriodStyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "right",
  minWidth: 87,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  '&[data-mui-is="MuiTableCell-root"]': {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const PeriodStyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#F8F9FA",
}))
