export const greeting = () => {
  const hour = new Date().getHours()

  if (hour < 12) {
    return "Good Morning"
  }

  if (hour >= 12 && hour < 18) {
    return "Good Afternoon"
  }

  return "Good Evening"
}
