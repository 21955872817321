import { createSlice } from "@reduxjs/toolkit"

interface User {
  firstName: string
  lastName: string
  email: string
}

interface State {
  user: null | User
}

const initialState: State = {
  user: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser(state, action) {
      state.user = action.payload
    },
  },
})

export const { addUser } = userSlice.actions
export const userReducer = userSlice.reducer
