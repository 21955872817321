import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined"
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"
import ScreenSearchDesktopOutlinedIcon from "@mui/icons-material/ScreenSearchDesktopOutlined"

export interface LicensingButtonType {
  title: string
  subTitle: string
  icon: React.ElementType
  href: string
}

export const licensingButtons: LicensingButtonType[] = [
  {
    title: "Purchase Fills",
    subTitle: "",
    icon: EvStationOutlinedIcon,
    href: "/licensing/purchase-fills",
  },
  {
    title: "Purchase History",
    subTitle: "",
    icon: CurrencyExchangeOutlinedIcon,
    href: "/licensing/purchase-history",
  },
  {
    title: "Fill Code Lookup",
    subTitle: "",
    icon: ScreenSearchDesktopOutlinedIcon,
    href: "/licensing/fill-lookup",
  },
]
