import { useEffect, type FC, useRef } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

interface ThreeSixtyMenuProps {
  id: string
  anchorEl: null | HTMLElement
  onClose: () => void
  options?: Array<{ label: string; action: () => void }>
}

export const ThreeSixtyMenu: FC<ThreeSixtyMenuProps> = ({ id, anchorEl, onClose, options }) => {
  const menuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const target = event.target as Node
      const menuElement = menuRef.current

      // Check if the scroll event occurs outside of the menu
      if (menuElement !== null && !menuElement.contains(target)) {
        onClose() // Close the menu when a scroll event occurs outside
      }
    }

    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll, true)
    }
  }, [onClose])

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      ref={menuRef}
      sx={{ zIndex: 1000 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {options?.map((option) => (
        <MenuItem dense onClick={option.action} key={option.label}>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  )
}
