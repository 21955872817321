import CheckCircleIcon from "@mui/icons-material/CheckCircle"

interface ListCheckCircleIconProps {
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "action"
    | "disabled"
    | "error"
    | "info"
    | "success"
    | "warning"
  size: number
}

export const ListCheckCircleIcon = ({ color = "primary", size }: ListCheckCircleIconProps) => {
  const iconStyle = {
    fontSize: `${size}px`,
  }

  return <CheckCircleIcon color={color} style={iconStyle} />
}
