import Typography from "@mui/material/Typography"

interface PurchaseHistoryTypographyStyledProps {
  children: React.ReactNode
  variant?: "body2"
  align?: "left" | "center" | "right"
}

export const PurchaseHistoryTypographyStyled = ({
  children,
  variant = "body2",
  align = "left",
}: PurchaseHistoryTypographyStyledProps) => (
  <Typography variant={variant} align={align}>
    {children}
  </Typography>
)
