import periodReportImg from "../assets/reports/icon-reportPeriod01a.svg"
import revenueReportImg from "../assets/reports/icon-reportRevenue01a.svg"
import cashReportImg from "../assets/reports/icon-reportAdjustments01a.svg"
import colectionsReportImg from "../assets/reports/icon-reportCollections01a.svg"
import currentCashReportImg from "../assets/reports/icon-reportCurrentCash01a.svg"
import salesReportImg from "../assets/reports/icon-reportSales01a.svg"
import performanceReportImg from "../assets/reports/icon-reportPerformance01a.svg"

export interface ReportButtonType {
  title: string
  subTitle: string
  icon: string
  href: string
}

export const reportButtons: ReportButtonType[] = [
  {
    title: "Period",
    subTitle: "Report",
    icon: periodReportImg,
    href: "/reports/period",
  },
  {
    title: "Revenue",
    subTitle: "Report",
    icon: revenueReportImg,
    href: "/reports/revenue",
  },
  {
    title: "Cash Adjustments",
    subTitle: "Report",
    icon: cashReportImg,
    href: "/reports/cash-adjustments",
  },
  {
    title: "Collections",
    subTitle: "Report",
    icon: colectionsReportImg,
    href: "/reports/collections",
  },
  {
    title: "Current Cash",
    subTitle: "Cash",
    icon: currentCashReportImg,
    href: "/reports/current-cash",
  },
  {
    title: "Sales",
    subTitle: "Report",
    icon: salesReportImg,
    href: "/reports/sales",
  },
  {
    title: "Performance",
    subTitle: "Report",
    icon: performanceReportImg,
    href: "/reports/performance",
  },
]
