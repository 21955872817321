export function formatPhoneNumber(input: string): string {
  if (typeof input !== "string") {
    return ""
  }
  // Find all groups of digits in the input.
  const digitGroups = input.match(/\d+/g)

  if (digitGroups === null) {
    // If there are no digits, return the original string.
    return input
  }

  // Join all digit groups into a single string.
  const allDigits = digitGroups.join("")

  // Find the original text (non-numeric parts).
  const originalText = input
    .replace(/\d+/g, "")
    .replace(/[.()-\s]+/g, " ")
    .trim()

  let formattedNumber = ""

  if (allDigits.length >= 11 && allDigits.length <= 13) {
    // If there are 11-13 digits, treat the first 1-3 as the country code
    // and format the rest as a phone number.
    const countryLength = allDigits.length - 10
    const countryCode = allDigits.slice(0, countryLength)
    const phoneNumber = allDigits.slice(countryLength)
    formattedNumber =
      "+" + countryCode + " " + phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
  } else if (allDigits.length === 10) {
    // If there are exactly 10 digits, format them as a phone number.
    formattedNumber = allDigits.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
  } else {
    // If there aren't exactly 10 digits or 11-13 digits, return the original string.
    return input
  }

  // Return the formatted number along with the original text.
  return originalText.length > 0 ? formattedNumber + " " + originalText : formattedNumber
}
