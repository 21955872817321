import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from "@mui/material"

export const BusinessDetailsSkeleton = () => (
  <div className="w-full sm:max-w-3xl mb-20 mx-auto">
    <Typography component="div" textAlign="center" variant="h4">
      Business Details
    </Typography>
    <Card variant="outlined">
      <CardHeader
        title={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              "@media (max-width:600px)": {
                gridTemplateColumns: "1fr",
                gap: 0,
              },
            }}
          >
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </Box>
        }
      />

      <CardContent>
        <Card>
          <CardHeader title={<Skeleton variant="text" />} />
          <CardContent
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              "@media (max-width:600px)": {
                gridTemplateColumns: "1fr",
                gap: 0,
              },
            }}
          >
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  </div>
)
