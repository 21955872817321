import Box from "@mui/material/Box"

interface RevenueBoxStyledProps {
  children: React.ReactNode
  textAlign?: "left" | "right" // Added optional textAlign prop
  color?: string
}

export const RevenueBoxStyled = ({ children, textAlign = "right" }: RevenueBoxStyledProps) => (
  <Box
    sx={{
      flex: "1 1 auto",
      textAlign,
      minWidth: "40px",
      flexBasis: "20%",
    }}
  >
    {children}
  </Box>
)
