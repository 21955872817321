import React from "react"
import FillCodeLookupCard from "./FillCodeLookupCard"
import type { FillData, FillLookupProps } from "../../../types/IFillCodeLookup"

export function FillLookupReport({ report }: FillLookupProps) {
  return (
    <div className="shadow-lg">
      {report.map(
        ({ machineId, purchaseDateTime, fillCode, amount, fillCount }: FillData, index: number) => (
          <React.Fragment key={index}>
            <FillCodeLookupCard {...{ machineId, purchaseDateTime, fillCode, amount, fillCount }} />
          </React.Fragment>
        )
      )}
    </div>
  )
}
