import { Box, Container, Grid, Skeleton, Typography } from "@mui/material"

export const AccountSettingsSkeleton = () => (
  <Container component="main" maxWidth="sm" sx={{ mb: 20 }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
        Account Settings
      </Typography>
      <Box component="form" noValidate sx={{ width: "100%", mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>
)
