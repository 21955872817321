import React from "react"
import { CustomDialog } from "./CustomDialog"

interface InactiveSessionDialogProps {
  timeLeft: number
  open: boolean
  totalTime?: number
  onPrimaryAction: () => void
  onSecondaryAction: () => void
}

const InactiveSessionDialog: React.FC<InactiveSessionDialogProps> = ({
  timeLeft,
  open,
  onPrimaryAction,
  onSecondaryAction,
  totalTime,
}) => {
  // convert time left to % to show on Linear Progress bar
  const progressBarValue =
    totalTime !== undefined && totalTime !== null ? (timeLeft / totalTime) * 100 : undefined
  return (
    <CustomDialog
      open={open}
      title="Session Timeout Warning"
      dialogContentText={`Your session is about to expire due to inactivity. Do you want to stay logged in?`}
      primaryActionText={`Yes (${timeLeft})`}
      secondaryActionText="No"
      onPrimaryAction={onPrimaryAction}
      onSecondaryAction={onSecondaryAction}
      fullScreen={false}
      disableHandleClose={true}
      hideCloseIcon={true}
      progressBarValue={progressBarValue}
    />
  )
}

export default InactiveSessionDialog
