import Box from "@mui/material/Box"

interface CurrentTerminalBoxStyledProps {
  children: React.ReactNode
  textAlign?: "left" | "right" // Added optional textAlign prop
}

export const CurrentTerminalBoxStyled = ({
  children,
  textAlign = "right",
}: CurrentTerminalBoxStyledProps) => (
  <Box
    sx={{
      flex: "1 1 auto",
      textAlign,
      minWidth: "40px",
      flexBasis: "20%",
    }}
  >
    {children}
  </Box>
)
