import { useState, useEffect } from "react"
import { TablePagination } from "@mui/material"

interface Props {
  count: number
  rowsPerPage: number
  page: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void
  onRowsPerPageChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  rowsPerPageOptions?:
    | Array<
        | number
        | {
            value: number
            label: string
          }
      >
    | undefined
  component: any
}

export function TablePaginationItem({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
  component,
}: Props) {
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  useEffect(() => {
    const handleScroll = (event: Event) => {
      setIsSelectOpen(false)
    }

    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll, true)
    }
  }, [])

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      SelectProps={{
        onOpen: () => {
          setIsSelectOpen(true)
        },
        onClose: () => {
          setIsSelectOpen(false)
        },
        open: isSelectOpen,
      }}
      component={component}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  )
}
