import { Outlet } from "react-router-dom"
import { useUserContext } from "../context/UserContext"

export function AuthLayout() {
  const data = useUserContext()

  const token = localStorage.getItem("token")

  if (token === null || token === undefined || token === "") {
    data.signOutHandler()
    return null
  }

  return <Outlet />
}
