import { type Dayjs } from "dayjs"

export interface ActivityMessage {
  type: "ACTIVITY_UPDATE" | "FORCE_LOGOUT" | "LOGOUT"
  lastActivityTime?: string
}

// Define the public interface for activity synchronization
interface ActivitySync {
  notifyActivity: (lastActivityTime: Dayjs) => void
  notifyForceLogout: () => void
  notifyLogout: () => void
  subscribe: (callback: (message: ActivityMessage) => void) => () => void
  closeChannel: () => void
}

// Create a singleton instance of the activity sync functionality
const createActivitySync = (): ActivitySync => {
  // Create a new broadcast channel for cross-tab communication
  // All tabs with the same channel name can communicate with each other
  const channel = new BroadcastChannel("user_activity_sync")

  // Notify other tabs that activity has occurred in the current tab
  // This is called when user interacts with the current tab or clicks "Yes" on inactivity dialog
  const notifyActivity = (lastActivityTime: Dayjs): void => {
    channel.postMessage({
      type: "ACTIVITY_UPDATE",
      lastActivityTime: lastActivityTime.toISOString(),
    })
  }

  // Notify other tabs that they should log out
  // This is called when user clicks "No" on inactivity dialog or when countdown expires
  const notifyForceLogout = (): void => {
    channel.postMessage({
      type: "FORCE_LOGOUT",
    })
  }

  // Notify other tabs to log out
  // This is called when user logs out in one tab
  const notifyLogout = (): void => {
    channel.postMessage({
      type: "LOGOUT",
    })
  }

  // Subscribe to messages from other tabs
  // Returns a cleanup function to remove the event listener
  // This is used to:
  // 1. Close inactivity dialogs when activity is detected in other tabs
  // 2. Log out when force logout is triggered from other tabs
  const subscribe = (callback: (message: ActivityMessage) => void): (() => void) => {
    const handler = (event: MessageEvent<ActivityMessage>) => {
      callback(event.data)
    }
    channel.addEventListener("message", handler)
    return () => {
      channel.removeEventListener("message", handler)
    }
  }

  // Close the broadcast channel when it's no longer needed
  // This prevents memory leaks and ensures proper cleanup
  const closeChannel = (): void => {
    channel.close()
  }

  return {
    notifyActivity,
    notifyForceLogout,
    notifyLogout,
    subscribe,
    closeChannel,
  }
}

export const activitySync = createActivitySync()
