// src/services/NavigationService.ts
let navigateFunction: (to: string, options?: { replace?: boolean; state?: any }) => void

export const setNavigateFunction = (navigate: typeof navigateFunction) => {
  navigateFunction = navigate
}

export const navigateTo = (path: string, options?: { replace?: boolean; state?: any }) => {
  if (typeof navigateFunction === "function") {
    navigateFunction(path, options)
  } else {
    console.error("Navigation function has not been initialized.")
  }
}
