import { reportButtons } from "../../data/reportspage"
import { ReportButton } from "../../components/ReportButton"

export function Reports() {
  return (
    <div className="pt-1 max-w-lg mx-auto mb-10">
      <h1 className="text-center text-2xl  font-bold">Reports</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 max-w-[480px] mx-auto px-5 mt-5">
        {reportButtons.map((value, index) => (
          <ReportButton key={index} href={value.href} value={value} />
        ))}
      </div>
    </div>
  )
}
