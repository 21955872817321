import React from "react"
import { type IBusiness } from "./TerminalsTypesFlattened"
import { BusinessCard } from "./TerminalsBusinessCard"

interface TerminalsBusinessesProps {
  terminalsData: IBusiness[]
  selectedBusinessId: string | null
  selectedId?: string
}

export function TerminalsBusinesses({
  terminalsData,
  selectedBusinessId,
  selectedId,
}: TerminalsBusinessesProps): React.ReactElement {
  return (
    <>
      {terminalsData
        .filter((business: IBusiness) => business.businessInfo.businessId === selectedBusinessId)
        .map((business: IBusiness, index) => {
          return (
            <React.Fragment key={`business-${index}-${business.businessInfo.businessId}`}>
              <BusinessCard business={business} selectedId={selectedId} />
            </React.Fragment>
          )
        })}
    </>
  )
}
