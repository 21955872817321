import { Box, Button, LinearProgress } from "@mui/material"
import customStyles from "../styles/tokens.json"

interface PrimaryButtonProps {
  primaryActionText: string
  progressBarValue: number
  disabledPrimaryAction?: boolean
  onPrimaryAction?: () => void
}

export const CustomLinearProgressButton = ({
  primaryActionText,
  onPrimaryAction,
  disabledPrimaryAction,
  progressBarValue,
}: PrimaryButtonProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Button
        sx={{
          fontSize: "min(16px, calc(10px + 0.8vmin))",
          width: "100%",
          zIndex: 2,
          backgroundColor: "transparent", // Make button background transparent
        }}
        variant="contained"
        onClick={onPrimaryAction}
        disabled={disabledPrimaryAction}
      >
        {primaryActionText}
      </Button>

      {/* LinearProgress as the background */}
      {progressBarValue !== null && progressBarValue !== undefined && (
        <LinearProgress
          variant="determinate"
          value={progressBarValue}
          className="progress"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            zIndex: 1, // Ensure it's behind the button
            backgroundColor: customStyles.themePalette.primary.lighter, // unfilled portion
            "& .MuiLinearProgress-bar": {
              backgroundColor: customStyles.components.buttons.primary.color, // filled portion
            },
          }}
        />
      )}
    </Box>
  )
}
