import { createSlice } from "@reduxjs/toolkit"

interface State {
  expandAll: boolean
  expanded: Record<string, boolean>
}

const initialState: State = {
  expandAll: false,
  expanded: {},
}

const buttonsSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setExpandAll: (state, action) => {
      state.expandAll = action.payload
    },
    setExpanded: (state, action) => {
      const id = action.payload.id
      state.expanded[id] = !state.expanded[id]
    },
    toggleExpandAll: (state, action: { payload: any[] }) => {
      const newExpandedState = !state.expandAll
      state.expandAll = newExpandedState

      state.expanded = action.payload.reduce((acc: Record<string, boolean>, business: any) => {
        acc[business.businessInfo.businessId] = newExpandedState
        return acc
      }, {})
    },
  },
})

export const { setExpandAll, setExpanded, toggleExpandAll } = buttonsSlice.actions
export const buttonsReducer = buttonsSlice.reducer
