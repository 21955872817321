import { type ComponentType, useState, useEffect, memo } from "react"
import Drawer from "@mui/material/Drawer"
import CloseIcon from "@mui/icons-material/Close"
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { type RootState } from "../redux/store"
import { applyFilters, clearFilters, initialState } from "../redux/filters/filters.slice"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import { styled } from "@mui/material/styles"
import { Filter } from "../components/Filter"
import { type FilterNameType } from "../redux/types"
import { type ChangeFilterType } from "../types/reportTypes"
import { useLocation, useSearchParams } from "react-router-dom"

interface Props {
  children?: JSX.Element
  Component: ComponentType<ComponentProps>
}

interface ComponentProps {
  openFiltersHandler?: () => void
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "white",
}))

const MemoizedComponent = memo(
  ({
    Component,
    openFiltersHandler,
  }: {
    Component: ComponentType<ComponentProps>
    openFiltersHandler: () => void
  }) => <Component openFiltersHandler={openFiltersHandler} />
)
MemoizedComponent.displayName = "MemoizedComponent"

export function FiltersLayout(props: Props) {
  const [openFilters, setOpenFilters] = useState(false)
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const transactionFilters = useSelector((state: RootState) => state.filters.transactionFilters)
  const productFilters = useSelector((state: RootState) => state.filters.productFilters)
  const statusFilters = useSelector((state: RootState) => state.filters.statusFilters)
  // const gameTerminalFilters = useSelector((state: RootState) => state.filters.gameTerminalFilters)

  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const drawerAnchor = isMobile ? "bottom" : "right"

  const openFiltersHandler = () => {
    setOpenFilters(true)
  }
  const closeFiltersHandler = () => {
    setOpenFilters(false)
  }

  const changeFilter: ChangeFilterType = (filterType, filter) => {
    return (event) => {
      const key = encodeURIComponent(`filter[${filter}][${filterType}]`)

      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams)
          if (event.target.checked) {
            newParams.set(key, String(event.target.checked))
          } else {
            newParams.delete(key)
          }
          return newParams
        },
        { replace: true }
      )

      if (!event.target.checked) {
        dispatch(
          applyFilters({
            filterType,
            value: event.target.checked,
            filter,
          })
        )
      }
    }
  }

  const clearFiltersHandler = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams)

      prevParams.forEach((value, key) => {
        key = decodeURIComponent(key)
        if (key.startsWith("filter[")) {
          console.log("SHOULD BE REMOVED", key)
          params.delete(encodeURIComponent(key))
        }
      })
      return params
    })

    Object.keys(initialState).forEach((filter) => {
      const filterName = filter as FilterNameType
      dispatch(clearFilters({ filter: filterName }))
    })
    setOpenFilters(false)
  }

  useEffect(() => {
    const queryFilters: Array<[FilterNameType, string, boolean]> = []
    searchParams.forEach((value, key) => {
      key = decodeURIComponent(key)
      if (key.startsWith("filter[")) {
        const parts = key.split("[").map((part) => part.replace("]", ""))
        const filter = parts[1] as FilterNameType
        const filterType = parts[2]
        queryFilters.push([filter, filterType, value === "true"])
      }
    })

    queryFilters.forEach(([filter, filterType, value]) => {
      dispatch(
        applyFilters({
          filterType,
          value,
          filter,
        })
      )
    })
  }, [searchParams])

  return (
    <div className="flex">
      <MemoizedComponent Component={props.Component} openFiltersHandler={openFiltersHandler} />

      <Drawer anchor={drawerAnchor} open={openFilters} onClose={closeFiltersHandler}>
        <div className="sm:w-72 w-screen flex flex-col h-screen">
          <div className="flex items-center justify-between px-4 ">
            <h1 className="font-semibold">Filters</h1>
            <IconButton size="large" onClick={closeFiltersHandler}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="sm:px-0 px-4">
            {(location.pathname === "/reports/cash-adjustments" ||
              location.pathname === "/reports/collections" ||
              location.pathname === "/reports/revenue" ||
              location.pathname === "/reports/sales" ||
              location.pathname === "/reports/current-cash" ||
              location.pathname === "/reports/performance" ||
              location.pathname === "/overview") && (
              <Accordion
                expanded={expandedPanel === "panel1"}
                onChange={(event, isExpanded) => {
                  setExpandedPanel(isExpanded ? "panel1" : false)
                }}
              >
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Product</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filter
                    filter="productFilters"
                    filters={productFilters}
                    changeFilter={changeFilter}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {/* <Accordion
              expanded={expandedPanel === "panel2"}
              onChange={(event, isExpanded) => {
                setExpandedPanel(isExpanded ? "panel2" : false)
              }}
            >
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Game Terminals</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Filter
                  filter="gameTerminalFilters"
                  filters={gameTerminalFilters}
                  changeFilter={changeFilter}
                />
              </AccordionDetails>
            </Accordion> */}

            {location.pathname === "/reports/cash-adjustments" && (
              <Accordion
                expanded={expandedPanel === "panel3"}
                onChange={(event, isExpanded) => {
                  setExpandedPanel(isExpanded ? "panel3" : false)
                }}
              >
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Transaction</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filter
                    filter="transactionFilters"
                    filters={transactionFilters}
                    changeFilter={changeFilter}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {(location.pathname === "/reports/cash-adjustments" ||
              location.pathname === "/reports/collections" ||
              location.pathname === "/reports/revenue" ||
              location.pathname === "/reports/sales" ||
              location.pathname === "/reports/current-cash" ||
              location.pathname === "/reports/performance" ||
              location.pathname === "/overview" ||
              location.pathname === "/reports/period") && (
              <Accordion
                expanded={expandedPanel === "panel4"}
                onChange={(event, isExpanded) => {
                  setExpandedPanel(isExpanded ? "panel4" : false)
                }}
              >
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Filter
                    filter="statusFilters"
                    filters={statusFilters}
                    changeFilter={changeFilter}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </div>
          <div className="flex justify-between px-4  mb-10 pt-5">
            <Button variant="outlined" color="primary" onClick={closeFiltersHandler}>
              <span>Close</span>
            </Button>
            <Button onClick={clearFiltersHandler} variant="contained" color="primary">
              Clear Filters
            </Button>
          </div>
        </div>
      </Drawer>
      {/* <div className="hidden sm:block">
        <IconButton className="place-self-start" size="large" onClick={openFiltersHandler}>
          <TuneIcon />
        </IconButton>
      </div> */}
    </div>
  )
}
