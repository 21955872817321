import { licensingButtons } from "../../data/licensingButtons"
import { LicenseButton } from "../../components/LicenseButton"

export function Licensing() {
  return (
    <div className="pt-1 max-w-lg mx-auto mb-10">
      <h1 className="text-center text-2xl  font-bold">Licensing</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 max-w-[480px] mx-auto px-5 mt-5">
        {licensingButtons.map((value, index) => (
          <LicenseButton key={index} href={value.href} value={value} />
        ))}
      </div>
    </div>
  )
}
