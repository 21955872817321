import { Link } from "react-router-dom"
import { type LicensingButtonType } from "../data/licensingButtons"

interface Props {
  href: string
  className?: string
  value: LicensingButtonType
}

export function LicenseButton({ href, value }: Props) {
  const className = "w-full mx-auto px-1 mt-2 group"
  const iconSize = { width: "32px", height: "32px" }
  const circleStyle = {
    backgroundColor: "white", // White background for the circle
    color: "black", // Icon color set to black
    padding: "8px", // Adjust the padding to size the circle
    borderRadius: "50%", // Makes the div circular
    border: "2px solid black", // Black border
  }

  return (
    <Link className={className} to={href}>
      <div className="flex flex-col justify-between cursor-pointer sm:h-32 h-32 w-full items-center rounded-lg bg-[#14213D] group-hover:bg-[#FCA311] active:bg-[#FCA311] group-hover:text-white">
        <p className="font-bold text-center mt-2 text-md text-white">{value.title}</p>
        <div className="rounded-full mb-3" style={circleStyle}>
          <value.icon style={iconSize} />
        </div>
      </div>
    </Link>
  )
}
