import React, { useState, useCallback, useEffect, useRef } from "react"
import useUserActivity from "../hooks/useUserActivity"
import InactiveSessionDialog from "./InactiveSessionDialog"
import { useUserContext } from "../context/UserContext"
import { decodeJwtTtl } from "../utils/decodeJwtTtl"
import { useDispatch } from "react-redux"
import { showInactivityToast } from "../redux/toastSlice"
import dayjs, { type Dayjs } from "dayjs"
import { activitySync } from "../utils/activitySync"
import { withScope, captureException } from "@sentry/react"

const inactivityBufferSeconds = 600
const countDownSeconds = 59

export const ActivityMonitoring: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [timeLeft, setTimeLeft] = useState(countDownSeconds)
  const { signOutHandler, refreshTokenHandler } = useUserContext()
  const dispatch = useDispatch()
  const token = localStorage.getItem("token") ?? ""
  const ttl = decodeJwtTtl(token)
  const inactiveTargetTimeRef = useRef<Dayjs | null>(null)

  // Subscribe to activity updates from other tabs
  // This is used to close the dialog when another tab is active
  // This is also used to logout the user when another tab logs out
  useEffect(() => {
    const cleanup = activitySync.subscribe((message) => {
      if (message.type === "FORCE_LOGOUT") {
        signOutHandler()
        dispatch(showInactivityToast("You have been signed out due to inactivity."))
      } else if (message.type === "ACTIVITY_UPDATE") {
        setDialogOpen(false)
      }
    })

    return () => {
      cleanup()
    }
  }, [signOutHandler, dispatch])

  const handleInactivity = useCallback(
    (lastActivityTime: Dayjs) => {
      const totalAllowedInactiveTime = countDownSeconds + inactivityBufferSeconds
      const targetTime = lastActivityTime.add(totalAllowedInactiveTime, "second")
      inactiveTargetTimeRef.current = targetTime
      const currentTime = dayjs()
      const remainingCountDown = Math.max(targetTime.diff(currentTime, "second"), 0) // Calculate remaining time in seconds

      // when inactive time elapsed but countdown is not 0 we will show the popup
      if (remainingCountDown > 0) {
        setTimeLeft(remainingCountDown)
        setDialogOpen(true)
      } else {
        activitySync.notifyForceLogout() // Notify all tabs to logout
        signOutHandler()
        dispatch(showInactivityToast("You have been signed out due to inactivity."))
      }
    },
    [signOutHandler, dispatch]
  )

  useUserActivity(inactivityBufferSeconds * 1000, handleInactivity)

  useEffect(() => {
    if (!isDialogOpen) return

    // Interval to update the UI with the current time left
    const countdownInterval = setInterval(() => {
      if (inactiveTargetTimeRef.current != null) {
        const currentTime = dayjs()
        const newTimeLeft = Math.max(inactiveTargetTimeRef.current.diff(currentTime, "second"), 0)
        setTimeLeft(newTimeLeft)

        // If time left is 0, signout user
        if (newTimeLeft <= 0) {
          setDialogOpen(false)
          activitySync.notifyForceLogout() // Notify all tabs to logout after countdown is reaches 0
          signOutHandler()
          dispatch(showInactivityToast("You have been signed out due to inactivity."))
        }
      }
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [isDialogOpen, signOutHandler, dispatch])

  const handlePrimaryAction = async () => {
    try {
      if (ttl !== null && ttl > 0) {
        if (ttl <= inactivityBufferSeconds) {
          await refreshTokenHandler()
        }
        setDialogOpen(false)
        activitySync.notifyActivity(dayjs()) // Notify all tabs of activity after clicking yes
      }
    } catch (error) {
      withScope((scope) => {
        scope.setTag("feature", "session_renewal")
        scope.setLevel("error")
        scope.setContext("session", {
          ttl,
          hasToken: Boolean(token),
          timeLeft,
          isDialogOpen,
        })
        captureException(error)
      })

      signOutHandler()
      dispatch(showInactivityToast("We could not renew your session. Please log in again."))
    }
  }

  return (
    <InactiveSessionDialog
      timeLeft={timeLeft}
      totalTime={countDownSeconds}
      open={isDialogOpen}
      onPrimaryAction={() => {
        void handlePrimaryAction()
      }}
      onSecondaryAction={() => {
        activitySync.notifyForceLogout()
        signOutHandler()
      }}
    />
  )
}
