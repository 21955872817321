// React core imports
import { useState, useEffect, Fragment, useMemo } from "react"

// React Router imports for navigation and URL management
import { useSearchParams } from "react-router-dom"

// Redux Toolkit Query for data fetching
import {
  useGetBusinessDetailsQuery,
  useGetTerminalDetailsQuery,
} from "../../redux/business/business.api"

// Material-UI core components
import { Box, Card, CardContent, Typography, CardHeader, Tooltip, Divider } from "@mui/material"

// Material-UI icons
import LocationOnIcon from "@mui/icons-material/LocationOn"
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded"
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import UpdateIcon from "@mui/icons-material/Update"

// MUI styled component
import { styled } from "@mui/material/styles"

// Type imports for TypeScript
import type { IBusiness, IBusinessInfo } from "../../types/businessDetailsTypes"
import type { TerminalDetails } from "../../types/detailsTypes"

// Utility functions
import { formatDate } from "../../utils/formatDate"
import { getAddressString } from "../../utils/getAddressString"

// Layout Component Imports
import { TerminalDetailsSkeleton } from "../../layouts/Skeletons/TerminalDetailsSkeleton"
import { terminalDetailsFields } from "../../data/terminalDetailsFields"
import { constructTerminalDetailsList } from "../../utils/constructTerminalDetailsList"

export const CardStyled = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  "&:first-of-type": { marginTop: 0 },
  "&:last-of-type": { marginBottom: theme.spacing(0) },
}))

export const CardContentStyled = styled(CardContent)({
  "&.MuiCardContent-root": {
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    marginTop: 0,
  },
})

export function TerminalDetailsPage() {
  // const userData = useUserContext()
  const [searchParams] = useSearchParams()
  const terminalId = searchParams.get("terminalId") ?? ""
  const locationId = searchParams.get("locationId") ?? ""
  const businessId = searchParams.get("businessId") ?? ""
  const {
    data: businessData,
    isLoading: isLoadingBusinessData,
    error: businessDataError,
  } = useGetBusinessDetailsQuery(businessId)
  const {
    data: terminalData,
    isLoading: isLoadingTerminalData,
    error: terminalDataError,
  } = useGetTerminalDetailsQuery(terminalId)
  const [terminalDetails, setTerminalDetails] = useState<TerminalDetails>()
  const [businessDetails, setBusinessDetails] = useState<IBusiness>()

  useEffect(() => {
    if (businessData?.data !== undefined) {
      setBusinessDetails(businessData?.data?.business)
    }
  }, [businessData])

  useEffect(() => {
    if (terminalData?.data !== undefined) {
      setTerminalDetails(terminalData?.data.terminal.terminal)
    }
  }, [terminalData])

  // Memoizes the shaping of terminal details into a list of items for rendering,
  // recalculating only if terminal details change.
  const detailItems = useMemo(() => {
    if (terminalDetails != null || terminalDetails !== undefined) {
      return constructTerminalDetailsList(terminalDetails, terminalDetailsFields)
    }
    return []
  }, [terminalDetails])

  if (businessDataError != null) {
    return <div>An error occurred while fetching business details. Please try again later. </div>
  }
  if (terminalDataError != null) {
    return <div>An error occurred while fetching terminal details. Please try again later. </div>
  }

  if (isLoadingTerminalData || isLoadingBusinessData) {
    return <TerminalDetailsSkeleton />
  }

  const store = businessDetails?.stores.find((store: { id: string }) => store?.id === locationId)
  const sofwareName = store !== undefined ? store?.sofwareName : null

  return (
    <div className="w-full sm:max-w-3xl mb-20 mx-auto">
      <Typography component="div" textAlign="center" variant="h4">
        Terminal Details
      </Typography>
      <CardStyled variant="outlined">
        <CardHeader
          sx={{
            marginX: 0,
            paddingX: 1,
            paddingY: { xs: 0.5, sm: 1 },
            borderRadius: "1px",
            backgroundColor: "#DEE2E6",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          title={terminalDetails?.name}
          subheader={
            <Box
              sx={{
                display: "grid",
                color: "#000c",
                marginTop: 1,
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                columnGap: 1,
                rowGap: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  alignItems: "left",
                  gap: 0,
                }}
              >
                <Typography variant="body2">{terminalDetails?.roleDisplayName}</Typography>
                <Typography variant="body2">
                  {locationId} {sofwareName}
                </Typography>
                <Typography variant="body2">{businessDetails?.businessInfo.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "18px 1fr",
                  rowGap: 0.5,
                  columnGap: 1,
                  alignItems: "flex-start",
                }}
              >
                <Tooltip title="Address" placement="left">
                  <LocationOnIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                </Tooltip>
                <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                  {getAddressString(businessDetails?.businessInfo as IBusinessInfo)}
                </Typography>

                {terminalDetails?.lastUpdated !== undefined &&
                  terminalDetails?.lastUpdated !== "" && (
                    <>
                      <Tooltip title="Last updated" placement="left">
                        <UpdateIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                      </Tooltip>
                      <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                        {formatDate(terminalDetails?.lastUpdated)}{" "}
                      </Typography>
                    </>
                  )}

                {terminalDetails?.connectTime !== undefined &&
                  terminalDetails?.connectTime !== "" && (
                    <>
                      {terminalDetails?.connectTime.includes("-") ? (
                        <Tooltip title="Connect time" placement="left">
                          <ArrowCircleDownRoundedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Connect time" placement="left">
                          <ArrowCircleUpRoundedIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
                        </Tooltip>
                      )}
                      <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                        {terminalDetails?.connectTime.replace("-", "")}
                      </Typography>
                    </>
                  )}
              </Box>
            </Box>
          }
        />
        <CardContentStyled>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "18px 1fr 2fr",
              rowGap: 0.5,
              columnGap: 1,
              alignItems: "flex-start",
            }}
          >
            {detailItems?.map((item, index) => (
              <Fragment key={index}>
                {item.errorCondition && (
                  <ErrorOutlineOutlinedIcon
                    sx={{ fontSize: 18, color: "red", gridColumnStart: 1 }}
                  />
                )}
                <Typography
                  variant="body2"
                  sx={{ gridColumnStart: 2, color: item.errorCondition ? "red" : "inherit" }}
                >
                  {item.label}:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ gridColumnStart: 3, color: item.errorCondition ? "red" : "inherit" }}
                >
                  {item.value}
                </Typography>
                {index !== detailItems.length - 1 && <Divider sx={{ gridColumn: "1 / -1" }} />}
              </Fragment>
            ))}
          </Box>
        </CardContentStyled>
      </CardStyled>
    </div>
  )
}
