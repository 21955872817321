import React from "react"
import { Skeleton, Stack } from "@mui/material"
interface Props {
  numCards: number
}
export function FillCodeLookupSkeleton({ numCards }: Props) {
  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20">
      <Stack spacing={1}>
        {[...Array(numCards)].map((_, index) => (
          <Stack key={index} spacing={1}>
            <Skeleton
              style={{ margin: 0, padding: 0 }}
              variant="rectangular"
              width="100%"
              height={"140px"}
            />
          </Stack>
        ))}
      </Stack>
    </div>
  )
}

export default FillCodeLookupSkeleton
