import { type AlertColor } from "@mui/material/Alert"
import { type FilterNameType } from "../redux/types"

export type HandleSnackBarType = (isOpen: boolean, severity: AlertColor, message: string) => void

export type HandleErrorType = (error: any) => void

export interface Location {
  id: string
  productType: string
}

export type ChangeFilterType = (
  filterType: string,
  filter: FilterNameType
) => (event: React.ChangeEvent<HTMLInputElement>) => void

export interface LocationsData {
  data: { allLocations: any }
}

export enum CalendarInputType {
  NoDate,
  Date,
  DateRange,
}

export enum LocationInputType {
  AutoLoad,
  SelectLoad,
  SelectGetLoad,
  NoLocation,
}
