import { createSlice } from "@reduxjs/toolkit"

interface FullscreenState {
  value: boolean
}

const initialState: FullscreenState = {
  value: false,
}

export const fullscreenSlice = createSlice({
  name: "fullscreen",
  initialState,
  reducers: {
    toggleFullscreen: (state) => {
      state.value = !state.value
    },
  },
})

export const { toggleFullscreen } = fullscreenSlice.actions
export const fullscreenReducer = fullscreenSlice.reducer
