import { useCallback, useEffect, useRef, useState } from "react"
import jsQR from "jsqr"
import toast from "react-hot-toast"

interface CornerPoints {
  topLeftCorner: { x: number; y: number }
  topRightCorner: { x: number; y: number }
  bottomRightCorner: { x: number; y: number }
  bottomLeftCorner: { x: number; y: number }
}

export const useQRScanner = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [data, setData] = useState("")
  const [isScanning, setIsScanning] = useState(false)
  const isScanningRef = useRef(isScanning)
  const [openScanDialog, setOpenScanDialog] = useState(false)

  const [isCameraDenied, setIsCameraDenied] = useState(false)

  const isVideoActiveRef = useRef(false)

  const videoStreamRef = useRef<MediaStream | null>(null)

  // 20 mb MAX_FILE_SIZE
  const MAX_FILE_SIZE = 20 * 1024 * 1024

  const resetScanning = useCallback(() => {
    setIsScanning(false)
    setData("")
  }, [])

  const handleScanClick = () => {
    setOpenScanDialog(true)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target
    if (fileInput.files?.[0] != null) {
      const file = fileInput.files[0]
      scanFromImage(file)
      // Reset the file input
      fileInput.value = ""
    }
  }

  const handleCloseDialog = () => {
    setOpenScanDialog(false)
    stopVideo()
    resetScanning()
  }

  const validateQRCodeData = (scannedData: string) => {
    const urlPattern = /^https?:\/\/.*\?c=.+/
    if (!urlPattern.test(scannedData)) {
      toast.dismiss()
      toast.error("Not a valid QR code.")
      handleCloseDialog()
      return false
    } else {
      const url = new URL(scannedData)
      const cParam = url.searchParams.get("c")
      if (cParam === null && cParam === "undefined") {
        toast.dismiss()
        toast.error("Not a valid QR code.")
        handleCloseDialog()
        return false
      }
    }
    return true
  }

  // Start the video feed without scanning
  const startVideo = useCallback(() => {
    setIsCameraDenied(false)
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        videoStreamRef.current = stream // Store the stream in the ref
        if (videoRef.current != null) {
          videoRef.current.srcObject = stream
          videoRef.current.play().catch((error) => {
            console.error("Video play failed", error)
          })
          isVideoActiveRef.current = true
        }
      })
      .catch((error) => {
        console.error("Error accessing the camera", error)
        if (error.name === "NotAllowedError") {
          toast.dismiss()
          toast.error("Camera access denied. Please allow camera access to continue.")
          setIsCameraDenied(true)
        }
      })
  }, [])

  // Stop the video feed
  const stopVideo = useCallback(() => {
    if (videoStreamRef.current !== null && videoStreamRef.current !== undefined) {
      videoStreamRef.current.getTracks().forEach((track) => {
        track.stop()
      })
      if (videoRef.current !== null && videoRef.current !== undefined) {
        videoRef.current.srcObject = null
      }
      videoStreamRef.current = null
      isVideoActiveRef.current = false
    }
  }, [])

  const drawGreenLine = (canvas: HTMLCanvasElement, corners: CornerPoints) => {
    const context = canvas.getContext("2d")
    if (context == null) return

    // Begin the drawing path
    context.beginPath()
    // Set the color of the line
    context.strokeStyle = "green"
    context.lineWidth = 4

    // Move to the first corner
    context.moveTo(corners.topLeftCorner.x, corners.topLeftCorner.y)
    // Draw the lines to each corner
    context.lineTo(corners.topRightCorner.x, corners.topRightCorner.y)
    context.lineTo(corners.bottomRightCorner.x, corners.bottomRightCorner.y)
    context.lineTo(corners.bottomLeftCorner.x, corners.bottomLeftCorner.y)
    // Connect back to the first corner
    context.lineTo(corners.topLeftCorner.x, corners.topLeftCorner.y)

    // Stroke the lines
    context.stroke()
  }

  // Scanning logic
  const scan = useCallback(() => {
    setData("")
    if (videoRef.current != null) {
      const video = videoRef.current
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        const canvas = document.createElement("canvas")
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const context = canvas.getContext("2d")
        if (context != null) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height)
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
          const qrCode = jsQR(imageData.data, imageData.width, imageData.height)
          if (qrCode != null && validateQRCodeData(qrCode.data)) {
            setData(qrCode.data)
            setIsScanning(false) // Stop scanning only after a QR code is found
            stopVideo() // Stop the video if desired after a scan
            drawGreenLine(canvas, qrCode.location)
          } else {
            // If no QR code is found, keep scanning
            requestAnimationFrame(scan)
          }
        } else {
          console.log("Canvas context not obtained.")
        }
      } else {
        // If the video isn't ready, keep scanning
        requestAnimationFrame(scan)
      }
    }
  }, [stopVideo])

  // Update the ref whenever the state changes
  useEffect(() => {
    isScanningRef.current = isScanning
    // When isScanning becomes true, start the scanning process
    if (isScanning) {
      scan() // Start the scanning process
    }
  }, [isScanning, scan])

  // Start scanning
  const initiateScan = useCallback(() => {
    if (!isVideoActiveRef.current) {
      startVideo()
    }
    setIsScanning(true)
    // Only start scanning if video is ready
    if (
      videoRef.current !== null &&
      videoRef.current !== undefined &&
      videoRef.current.readyState >= 2
    ) {
      scan()
    }
  }, [scan, startVideo])
  // Stop scanning
  const stopScan = useCallback(() => {
    setIsScanning(false)
  }, [])

  // Scan from an image file
  const scanFromImage = useCallback((file: File) => {
    toast.dismiss()
    toast.loading("Uploading QR, please  wait...")
    setData("")

    // Check for the file size
    if (file.size > MAX_FILE_SIZE) {
      toast.error("This file is too large. Keep it under 20 MB.")
      return
    }
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")
        canvas.width = image.width
        canvas.height = image.height
        context?.drawImage(image, 0, 0, canvas.width, canvas.height)
        const imageData = context?.getImageData(0, 0, canvas.width, canvas.height)
        if (imageData != null) {
          const qrCode = jsQR(imageData.data, imageData.width, imageData.height)
          if (qrCode != null) {
            const isValid = validateQRCodeData(qrCode.data)
            if (isValid) {
              toast.dismiss()
              setData(qrCode.data)
            }
          } else {
            toast.dismiss()
            toast.error("No QR code found.")
          }
        }
      }
      image.src = e.target?.result as string
    }
    reader.onerror = () => {
      console.error("Failed to read file.")
      toast.dismiss()
    }
    reader.readAsDataURL(file)
  }, [])

  // Automatically start the video when the component mounts
  useEffect(() => {
    // Return a cleanup function
    return () => {
      stopVideo()
    }
  }, [stopVideo])

  return {
    videoRef,
    data,
    scanFromImage,
    initiateScan,
    stopScan,
    stopVideo,
    openScanDialog,
    setOpenScanDialog,
    handleScanClick,
    handleCloseDialog,
    handleFileUpload,
    isCameraDenied,
  }
}
