/**
 * Converts a raw byte value into a human-readable formatted string.
 * The function dynamically determines the appropriate unit (bytes, KB, MB, GB, etc.)
 * to use based on the size of the input and formats the value accordingly.
 *
 * @param rawBytes - The raw storage size in bytes as a string, possibly including commas.
 * @returns A formatted string representing the storage size in the most appropriate unit.
 */
export function formatBytes(rawBytes: string): string {
  // Parse the input string to an integer, removing any commas.
  const bytes = parseInt(rawBytes.replace(/,/g, ""), 10)

  // Guard clause for non-numeric values or zero, returning '0 bytes' as a sensible default.
  if (isNaN(bytes) || bytes === 0) return "0 bytes"

  // Define the units of measurement from smallest to largest.
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const k = 1024 // The base value for byte conversions.

  // Calculate the logarithm of the byte value divided by logarithm of 1024 (base change formula),
  // to determine the power of 1024 that fits the byte size. This identifies the index for the units array.
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // Convert the byte value to the selected unit by dividing by 1024 to the power of the calculated index,
  // formatting the result to two decimal places for readability.
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${units[i]}`
}
