import React, { useEffect, useState } from "react"
import { formatAmount } from "../../../utils/formatAmount"
import {
  Alert,
  Backdrop,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"
import {
  useGetCreateCheckoutMutation,
  useGetCreateInvoiceMutation,
} from "../../../redux/business/business.api"
import type { ContactDetailsResponse } from "../../../types/contactDetailsTypes"
import { ReactComponent as AnimatedShoppingCard } from "../../../assets/licensing/animated_shopping_cart.svg"
import type { CartItem } from "../../../types/cartItemTypes"
import { CustomDialog } from "../../CustomDialog"
import { useNavigate } from "react-router-dom"
interface CheckoutProps {
  cartItems: CartItem[]
  getTotalPurchase: () => number
  setFillActionsLoading: React.Dispatch<React.SetStateAction<boolean>>
  fillActionsLoading: boolean
  allMachinesHavePrices: boolean
  contactDetailsData: ContactDetailsResponse
  refetchCartItemData: any
}

export const Checkout: React.FC<CheckoutProps> = ({
  cartItems,
  getTotalPurchase,
  setFillActionsLoading,
  fillActionsLoading,
  allMachinesHavePrices,
  contactDetailsData,
  refetchCartItemData,
}: CheckoutProps) => {
  const returnRedirectUrl = `${window.location.protocol}//${window.location.host}/licensing/payment-status`
  const navigate = useNavigate()
  const [createCheckout] = useGetCreateCheckoutMutation()
  const [createInvoice] = useGetCreateInvoiceMutation()
  const [spinnerCheckout, setSpinnerCheckout] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null)
  const [openCartChangedDialog, setOpenCartChangedDialog] = useState(false)
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(false)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const paymentMethods = contactDetailsData?.data?.paymentMethods
  const fillInvoice = paymentMethods?.fillInvoice
  const fillCC = paymentMethods?.fillCC

  useEffect(() => {
    if (contactDetailsData !== null && contactDetailsData !== undefined) {
      if (fillInvoice && !fillCC) {
        setSelectedPaymentMethod("Invoice")
      } else if (!fillInvoice && fillCC) {
        setSelectedPaymentMethod("Credit Card")
      }
    }
  }, [fillInvoice, fillCC])

  useEffect(() => {
    if (!allMachinesHavePrices) {
      setOpenCartChangedDialog(true)
    }
  }, [allMachinesHavePrices])

  const handleCheckoutConfirmation = async () => {
    setShowInvoiceConfirmation(false)
    await handleCheckout()
  }

  const handleCheckoutClick = () => {
    if (selectedPaymentMethod === "Invoice") {
      setShowInvoiceConfirmation(true) // Show confirmation dialog for Invoice
    } else {
      handleCheckout().catch((error) => {
        console.error("Error during checkout:", error)
      })
    }
  }

  const handleCheckout = async () => {
    setFillActionsLoading(true)
    setSpinnerCheckout(true)
    try {
      const { data: refetchedData } = await refetchCartItemData().unwrap()

      const refetchedCartItems = refetchedData.cartItems.map((item: CartItem) => ({
        cartItemId: item.cartItemId,
        amount: item.amount,
        machineId: item.machineId,
        price: item.price,
      }))

      const hasChanges =
        cartItems.length !== refetchedCartItems.length ||
        cartItems.some((item) => {
          const foundItem: CartItem = refetchedCartItems.find(
            (refetch: CartItem) => refetch.cartItemId === item.cartItemId
          )
          return (
            String(foundItem?.amount) !== item?.amount ||
            foundItem?.price !== item?.price ||
            foundItem?.machineId !== item?.machineId
          )
        })

      if (hasChanges) {
        setOpenCartChangedDialog(true)
      } else {
        const checkoutMethod = selectedPaymentMethod === "Invoice" ? createInvoice : createCheckout

        const response = await checkoutMethod({
          returnRedirectUrl,
          purchaseMethod: selectedPaymentMethod,
        }).unwrap()

        // Check if response code is 1000
        if (response?.rc === 1000) {
          if (selectedPaymentMethod === "Invoice") {
            if (response?.data?.invoiceId !== null && response?.data?.invoiceId !== undefined) {
              localStorage.setItem("invoiceId", response.data.invoiceId)
              localStorage.removeItem("orderId")
              navigate("/licensing/payment-status")
            }
          } else if (
            response?.data?.checkout?.url !== null &&
            response?.data?.checkout?.url !== undefined
          ) {
            if (
              response?.data?.checkout?.orderId !== null &&
              response?.data?.checkout?.orderId !== undefined
            ) {
              localStorage.setItem("orderId", response.data.checkout.orderId)
              localStorage.removeItem("invoiceId")
            }
            window.location.href = response.data.checkout.url
            console.log("Checkout successful")
          } else {
            console.log("Unexpected response format:", response)
          }
        } else {
          console.log("Checkout response:", response)
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setSpinnerCheckout(false)
      setFillActionsLoading(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenCartChangedDialog(false)
  }

  const goToPurchaseFills = () => {
    navigate("/licensing/purchase-fills")
  }

  return (
    <>
      <div
        className={`flex ${
          fillCC !== null &&
          fillCC !== undefined &&
          fillCC &&
          fillInvoice !== null &&
          fillInvoice !== undefined &&
          fillInvoice
            ? "justify-between"
            : "justify-end"
        } items-center px-2`}
      >
        {spinnerCheckout && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: 2147483647, // The highest possible z-index value
              position: "fixed", // Change from absolute to fixed
              top: 0, // Ensure it starts from the very top of the viewport
              left: 0, // Ensure it starts from the very left of the viewport
              width: "100vw", // Cover the full viewport width
              height: "100vh", // Cover the full viewport height
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center spinner vertically
            }}
            open={true}
          >
            <AnimatedShoppingCard />
            <Typography variant="h5">Checking out...</Typography>
          </Backdrop>
        )}
        {fillCC !== null &&
          fillCC !== undefined &&
          fillCC &&
          fillInvoice !== null &&
          fillInvoice !== undefined &&
          fillInvoice && (
            <RadioGroup
              name="use-radio-group"
              value={selectedPaymentMethod}
              onChange={(event) => {
                setSelectedPaymentMethod(event.target.value)
              }}
              row={!isSmallScreen}
            >
              <FormControlLabel
                value="Credit Card"
                label="Credit Card"
                disabled={!fillCC || spinnerCheckout}
                control={<Radio />}
              />

              <FormControlLabel
                value="Invoice"
                label="Invoice"
                disabled={!fillInvoice || spinnerCheckout}
                control={<Radio />}
              />
            </RadioGroup>
          )}

        {getTotalPurchase() > 50000 && allMachinesHavePrices ? (
          <Alert severity="warning" sx={{ width: "100%" }}>
            {/* to Rus parametrize the value */}
            <Typography variant="body1">
              Your purchase cannot be over $50,000 in a single transaction. Please adjust your cart.
            </Typography>
          </Alert>
        ) : (
          <Button
            startIcon={<ShoppingCartCheckoutOutlinedIcon />}
            disabled={
              fillActionsLoading ||
              getTotalPurchase() === 0 ||
              !allMachinesHavePrices ||
              selectedPaymentMethod === null
            }
            variant="contained"
            sx={{
              backgroundColor: "#FCA311",
              color: "white",
              height: "36.5px",
              "&:hover": {
                backgroundColor: "rgba(252, 163, 17, 0.8)",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(252, 163, 17, 0.3)",
              },
            }}
            onClick={handleCheckoutClick}
          >
            Checkout
            {allMachinesHavePrices && (
              <span className="font-bold pl-1">{formatAmount(getTotalPurchase()).full}</span>
            )}
          </Button>
        )}
      </div>

      <CustomDialog
        open={openCartChangedDialog}
        title=""
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        dialogContentText={
          !allMachinesHavePrices
            ? "Your items have been updated. Please select a fill in order to proceed with checkout."
            : "Your items have been updated. Please review your order and proceed to checkout."
        }
        primaryActionText={!allMachinesHavePrices ? "BACK" : "OK"}
        onPrimaryAction={!allMachinesHavePrices ? goToPurchaseFills : handleCloseDialog}
        handleClose={handleCloseDialog}
      />

      <CustomDialog
        open={showInvoiceConfirmation}
        title=""
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        dialogContentText="You selected to pay by invoice. Are you sure you want to continue?"
        primaryActionText="Checkout"
        secondaryActionText="Cancel"
        onPrimaryAction={() => {
          handleCheckoutConfirmation().catch((error) => {
            console.error("Error during checkout:", error)
          })
        }}
        onSecondaryAction={() => {
          setShowInvoiceConfirmation(false)
        }}
        handleClose={() => {
          setShowInvoiceConfirmation(false)
        }}
      />
    </>
  )
}
