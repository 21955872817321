import { createSlice } from "@reduxjs/toolkit"
import { type OutputJson as OutputJsonRevenue } from "../../middlewares/flattenRevenue"
import { type FlattenedSalesReport } from "../../types/ISalesReportTypes"

interface DataState {
  revenueData: OutputJsonRevenue | null
  salesData: FlattenedSalesReport | null
}

const initialState: DataState = {
  revenueData: null,
  salesData: null,
}

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    saveRevenueData(state, action) {
      state.revenueData = action.payload
    },
    saveSalesData(state, action) {
      state.salesData = action.payload
    },
  },
})

export const { saveRevenueData, saveSalesData } = dataSlice.actions
export const dataReducer = dataSlice.reducer
