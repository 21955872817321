import { useState } from "react"
import {
  Box,
  TextField,
  InputAdornment,
  Icon,
  CircularProgress,
  Typography,
  Button,
  Alert,
} from "@mui/material"
import { Email } from "@mui/icons-material"

interface PasswordResetRequestProps {
  loading: boolean
  email: string
  emailError: string
  errorMessage: string
  setEmail: (email: string) => void
}

export const PasswordResetRequest = ({
  loading,
  email,
  emailError,
  errorMessage,
  setEmail,
}: PasswordResetRequestProps) => {
  const [shake, setShake] = useState(false)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
  }

  const handleButtonClick = () => {
    if (emailError.length > 0) {
      setShake(false) // Reset shake state to force re-trigger
      setTimeout(() => {
        setShake(true)
      }, 0) // Re-trigger shake animation
    }
  }

  const disableResetButton = loading || email === ""

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="body1">
        Enter your email address to receive instructions on how to reset your password.
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError !== ""}
        helperText={emailError.length > 0 ? emailError : " "}
        autoFocus
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ margin: "0px -12px 0px 0px", padding: "8px" }}>
                <Icon component={Email} />
              </Box>
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          className: shake ? "animate-shake" : "",
        }}
      />
      {errorMessage !== "" && (
        <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={disableResetButton}
        sx={{
          mt: "4px",
          mb: 2,
          position: "relative",
        }}
        onClick={handleButtonClick}
      >
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        Reset Password
      </Button>
    </>
  )
}
