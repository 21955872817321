import type { IBusinessInfo } from "../types/businessDetailsTypes"

export const getAddressString = (businessInfo: IBusinessInfo) => {
  const parts = [
    businessInfo?.street,
    businessInfo?.city,
    businessInfo?.locality,
    businessInfo?.zipPostal,
    businessInfo?.country,
  ].filter(Boolean) // This removes any falsy values from the array, including empty strings

  return parts.join(", ")
}
