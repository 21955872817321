import Button from "@mui/material/Button"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"
import DialpadIcon from "@mui/icons-material/Dialpad"
import FilterIcon from "@mui/icons-material/Filter"
import { useQRScanner } from "../../../hooks/useQRScanner"
import React, { useEffect, useRef, useState } from "react"
import { CustomDialog } from "../../CustomDialog"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useGetFillPricingQuery } from "../../../redux/business/business.api"
import CloseIcon from "@mui/icons-material/Close"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { formatAmount } from "../../../utils/formatAmount"
import { PricingTableGridItemStyled } from "../../../styles/PricingTableGridItemStyled"
import { IMaskInput } from "react-imask"
interface ScannerControlsProps {
  handleDecodeRequest: (newMachineCode: string, code?: string | undefined) => Promise<void>
  showCodeInput: boolean
  setShowCodeInput: (show: boolean) => void
  isSubmitting: boolean
  fillActionsLoading: boolean
  onScanned: (data: string) => void
}

interface PricingData {
  fillPricing: Record<string, number>
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask="*****-*****-*****-*****-*****-*****-*****-*****"
      definitions={{
        "*": /[A-HJ-NP-Z2-9]/i,
      }}
      inputRef={ref as React.RefObject<HTMLInputElement>}
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value: value.toUpperCase() } })
      }}
      overwrite={false}
      lazy={false}
      prepare={(str) => str.toUpperCase()}
    />
  )
})

export const ScannerControls: React.FC<ScannerControlsProps> = ({
  handleDecodeRequest,
  showCodeInput,
  setShowCodeInput,
  isSubmitting,
  fillActionsLoading,
  onScanned,
}: ScannerControlsProps) => {
  const {
    videoRef,
    data,
    initiateScan,
    stopVideo,
    openScanDialog,
    handleScanClick,
    handleCloseDialog,
    handleFileUpload,
    isCameraDenied,
  } = useQRScanner()

  const inputRef = useRef<HTMLInputElement>(null)
  const [openPricingDialog, setOpenPricingDialog] = useState(false)
  const [inputMachineCode, setInputMachineCode] = useState("")
  const MAX_PRICE_PER_COLUMN = 8

  const [shouldFetchPricing, setShouldFetchPricing] = useState(false)

  const {
    data: pricingData,
    isLoading: isLoadingPricing,
    error,
  } = useGetFillPricingQuery(undefined, { skip: !shouldFetchPricing })

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (data.length > 0) {
      console.log("SCANNER DATA", data)
      onScanned(data)
      handleCloseDialog()
    }
  }, [data, onScanned])

  useEffect(() => {
    if (openScanDialog) {
      initiateScan()
    }
    return () => {
      stopVideo()
    }
  }, [openScanDialog, stopVideo])

  // Focus the TextField with a delay to prevent cursor from jumping to the end on dialog open
  useEffect(() => {
    if (showCodeInput) {
      const timer = setTimeout(() => {
        if (inputRef.current != null) {
          inputRef.current.focus()
          inputRef.current.setSelectionRange(0, 0)
        }
      }, 100)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [showCodeInput])

  const handleOpenPricingDialog = () => {
    setShouldFetchPricing(true)
    setOpenPricingDialog(true)
  }

  const handleClosePricingDialog = () => {
    setOpenPricingDialog(false)
    setShouldFetchPricing(false)
  }

  const handleInputChange = (event: { target: { value: string } }) => {
    const input = event.target.value
    setInputMachineCode(input)
  }

  const isPricingDataValid =
    pricingData !== null &&
    pricingData !== undefined &&
    Object.entries(pricingData.data.fillPricing).length >= MAX_PRICE_PER_COLUMN

  return (
    <>
      <div className="text-center">
        <Grid container spacing={1} paddingX={1}>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<CameraAltIcon />}
              fullWidth
              disabled={isSubmitting || fillActionsLoading}
              variant="contained"
              color="primary"
              onClick={handleScanClick}
            >
              Scan
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<FilterIcon />}
              fullWidth
              disabled={isSubmitting || fillActionsLoading}
              variant="contained"
              color="primary"
              component="label"
            >
              Upload
              <input type="file" accept="image/*" className="hidden" onChange={handleFileUpload} />
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              disabled={isSubmitting || fillActionsLoading}
              startIcon={<DialpadIcon />}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setShowCodeInput(true)
              }}
            >
              Enter
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              startIcon={<ShoppingCartCheckoutOutlinedIcon />}
              disabled={isSubmitting || fillActionsLoading || isLoadingPricing}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleOpenPricingDialog}
            >
              {isLoadingPricing && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Pricing
            </Button>
          </Grid>
        </Grid>
      </div>
      <CustomDialog
        open={openScanDialog}
        title="Scan QR"
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        content={
          <Box className="block relative w-full h-[350px] overflow-hidden">
            {isCameraDenied ? (
              <div>
                <Alert
                  icon={false}
                  severity="error"
                  className="w-full h-[350px] object-cover justify-center"
                >
                  <div className="flex flex-col items-center w-full h-full  justify-around">
                    <NoPhotographyIcon sx={{ fontSize: "154px" }} />
                    <Typography variant="body1">
                      Camera access denied. Please enable camera access in your browser settings.
                    </Typography>
                  </div>
                </Alert>
              </div>
            ) : (
              <video
                ref={videoRef}
                className="w-full h-full object-cover"
                playsInline
                muted
                autoPlay
              />
            )}
          </Box>
        }
        primaryActionText="Cancel"
        secondaryActionText={isCameraDenied ? "Retry Camera Access" : undefined}
        onPrimaryAction={handleCloseDialog}
        onSecondaryAction={() => {
          initiateScan()
        }}
        handleClose={handleCloseDialog}
      />

      <CustomDialog
        open={showCodeInput}
        title="Enter Code"
        centerTitle={true}
        isLoading={isSubmitting}
        fullScreen={false}
        hideCloseIcon={true}
        content={
          <TextField
            disabled={isSubmitting}
            label="Fill Request Code"
            variant="outlined"
            margin="normal"
            fullWidth
            autoComplete="off"
            inputRef={inputRef}
            value={inputMachineCode}
            onChange={handleInputChange}
            InputProps={{
              inputComponent: TextMaskCustom as any,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        primaryActionText="Submit"
        secondaryActionText="Cancel"
        disabledPrimaryAction={
          isSubmitting || inputMachineCode.replace(/[^A-HJ-NP-Z2-9]/g, "").length < 40
        }
        disabledSecondaryAction={isSubmitting}
        onPrimaryAction={() => {
          handleDecodeRequest(inputMachineCode)
            .then(() => {
              setInputMachineCode("")
            })
            .catch((err) => {
              console.error("Error submitting code:", err)
            })
        }}
        onSecondaryAction={() => {
          setShowCodeInput(false)
          setInputMachineCode("")
        }}
        handleClose={() => {
          setShowCodeInput(false)
          setInputMachineCode("")
        }}
      />
      <Dialog
        open={openPricingDialog}
        onClose={handleClosePricingDialog}
        className="rounded-md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <div className="w-full mx-auto">
          <DialogTitle style={{ padding: "0" }} className="bg-[#14213D] h-[48px] relative">
            <div className="flex justify-center items-center text-white p-2">
              <h1 className="font-bold">Pricing</h1>
            </div>
            <div className="absolute top-0 right-0 bottom-0 m-auto flex items-center">
              <IconButton aria-label="close" onClick={handleClosePricingDialog}>
                <CloseIcon className="text-white" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            {isLoadingPricing ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
              </Box>
            ) : error != null ? (
              <Alert severity="error">Error fetching pricing data.</Alert>
            ) : (
              <>
                <Grid
                  container
                  spacing={0}
                  className="font-bold text-right"
                  sx={{ display: "grid" }}
                  gridTemplateColumns={
                    !isPricingDataValid || isSmallScreen ? "1fr 1fr" : "1fr 1fr 1fr 1fr"
                  }
                >
                  <Grid
                    sx={{
                      paddingRight: "4px",
                      borderBottom: "1px dotted #bbb2a3a1",
                      backgroundColor: "#F2F2F2",
                    }}
                    item
                  >
                    Fill
                  </Grid>
                  <Grid
                    sx={{
                      paddingRight: "4px",
                      borderBottom: "1px dotted #bbb2a3a1",
                      backgroundColor: "#E5E5E5",
                    }}
                    item
                  >
                    Price
                  </Grid>
                  {isPricingDataValid && !isSmallScreen && (
                    <>
                      <Grid
                        sx={{
                          paddingRight: "4px",
                          borderBottom: "1px dotted #bbb2a3a1",
                          backgroundColor: "#F2F2F2",
                        }}
                        item
                      >
                        Fill
                      </Grid>
                      <Grid
                        sx={{
                          paddingRight: "4px",
                          borderBottom: "1px dotted #bbb2a3a1",
                          backgroundColor: "#E5E5E5",
                        }}
                        item
                      >
                        Price
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "grid",
                  }}
                  gridTemplateColumns={!isPricingDataValid || isSmallScreen ? "1fr" : "1fr 1fr"}
                >
                  <div>
                    {Boolean(pricingData) &&
                      Object.entries(pricingData.data.fillPricing as PricingData)
                        .slice(0, MAX_PRICE_PER_COLUMN)
                        .map(([key, value]) => (
                          <Grid
                            key={key}
                            item
                            container
                            sx={{
                              alignItems: "center",
                              display: "grid",
                            }}
                            gridTemplateColumns={"1fr 1fr"}
                          >
                            <PricingTableGridItemStyled sx={{ backgroundColor: "#F2F2F2" }}>
                              {key}
                            </PricingTableGridItemStyled>
                            <PricingTableGridItemStyled sx={{ backgroundColor: "#E5E5E5" }}>
                              {formatAmount(value).full}
                            </PricingTableGridItemStyled>
                          </Grid>
                        ))}
                  </div>
                  <div>
                    {isPricingDataValid && (
                      <Grid container spacing={0}>
                        {Boolean(pricingData) &&
                          Object.entries(pricingData.data.fillPricing as PricingData)
                            .slice(MAX_PRICE_PER_COLUMN)
                            .map(([key, value]) => (
                              <Grid
                                key={key}
                                item
                                xs={12}
                                container
                                sx={{
                                  alignItems: "center",
                                  display: "grid",
                                }}
                                gridTemplateColumns={"1fr 1fr"}
                              >
                                <PricingTableGridItemStyled sx={{ backgroundColor: "#F2F2F2" }}>
                                  {key}
                                </PricingTableGridItemStyled>
                                <PricingTableGridItemStyled sx={{ backgroundColor: "#E5E5E5" }}>
                                  {formatAmount(value).full}
                                </PricingTableGridItemStyled>
                              </Grid>
                            ))}
                      </Grid>
                    )}
                  </div>
                </Grid>
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  )
}
