import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

interface Option {
  name: string
  id: number | null
}

interface Props {
  options: Option[]
  onChange: (e: any, value: number | null) => void
  label: any
  value: any
  selectedValue: Option
  setSelectedValue: (value: Option | null) => void
  width?: string
  disabled?: boolean
  excludeAllLocations?: boolean
}

export function AutoCompleteDropDown(props: Props) {
  const allLocationsOption = { name: "All Locations", id: null }

  const options =
    props.excludeAllLocations ?? false ? props.options : [allLocationsOption, ...props.options]

  return (
    <Autocomplete
      className="-mt-4 -mb-2 sm:w-64"
      options={options}
      disableClearable={true}
      blurOnSelect={true}
      disabled={props.disabled}
      getOptionLabel={(option: any) => option.name}
      value={props.selectedValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, value) => {
        props.setSelectedValue(value)
        props.onChange(e, value != null ? value.id : null)
      }}
      renderInput={(params) => <TextField {...params} label={props.label} margin="normal" />}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue, {
          insideWords: true,
        })
        const parts = parse(option.name, matches)

        return (
          <li {...props} key={option.id}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}
