import CircleIcon from "@mui/icons-material/Circle"
import { Tooltip } from "@mui/material"

interface StatusCircleIconProps {
  status: string
  size: number
  needMarginTop?: boolean
}

export const StatusCircleIcon = ({
  status,
  size,
  needMarginTop = false,
}: StatusCircleIconProps) => {
  const getStatusColor = () => {
    switch (status) {
      case "online":
        return "green"
      case "offline":
        return "#e70f0fde"
      case "hold":
        return "gray"
      case "closed":
        return "black"
      default:
        return "grey"
    }
  }

  const circleIconStyle = {
    color: getStatusColor(),
    fontSize: `${size}px`,
    marginRight: "4px",
    marginTop: needMarginTop ? "-2px" : 0,
  }

  return (
    <Tooltip placement="left" title={status}>
      <CircleIcon style={circleIconStyle} />
    </Tooltip>
  )
}
