export function formatAmount(amount: number): { full: string; short: string } {
  if (typeof amount !== "number") {
    console.error(`Expected a number, but got ${typeof amount}`)
    return { full: "N/A", short: "N/A" } // Or any other default value
  }

  const fullFormat = amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const shortFormat =
    amount >= 1000000
      ? (amount / 1000000).toFixed(1) + "M"
      : amount >= 1000
      ? (amount / 1000).toFixed(1) + "k"
      : amount.toFixed(2)

  return { full: `$${fullFormat}`, short: `$${shortFormat}` }
}

export function formatNegative(amount: number): { full: string; short: string } {
  const isNegative = amount < 0

  const fullFormat = Math.abs(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const shortFormat =
    Math.abs(amount) >= 1000000
      ? (Math.abs(amount) / 1000000).toFixed(1) + "M"
      : Math.abs(amount) >= 1000
      ? (Math.abs(amount) / 1000).toFixed(1) + "k"
      : Math.abs(amount).toFixed(2)

  const fullFormattedAmount = isNegative ? `($${fullFormat})` : `$${fullFormat}`
  const shortFormattedAmount = isNegative ? `($${shortFormat})` : `$${shortFormat}`

  return { full: fullFormattedAmount, short: shortFormattedAmount }
}

// Remaining functions...

export function formatPercent(amount: number): string {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
  return formattedAmount
}

export function formatNegativePercent(amount: number): string {
  const isNegative = amount < 0
  const formattedAmount = Math.abs(amount) // Use the absolute value to ensure positive formatting
  const roundedAmount = Math.fround(formattedAmount)

  // If negative, prepend the minus sign outside the parentheses
  return isNegative ? `(${roundedAmount.toFixed(1)}%)` : `${roundedAmount.toFixed(1)}%`
}
export const formatAmountNoCents = (amount: string) => {
  const cleanedAmount = amount.replace(/[$,]/g, "").trim()

  const numericAmount = parseFloat(cleanedAmount)

  if (!isNaN(numericAmount)) {
    return `${numericAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}`
  } else {
    return ""
  }
}
