import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Grid from "@mui/material/Grid"

interface Props {
  reportName: string
}

export function PurchaseFillsSkeleton({ reportName }: Props) {
  return (
    <div className="w-full sm:max-w-4xl mx-auto">
      <div className="shadow-lg">
        <div className="flex justify-between py-2 px-4 bg-[#14213D]">
          <h1 className="text-2xl text-center font-bold grow text-white">{reportName}</h1>
        </div>
        <div className="grid grid-cols-1 gap-2 my-4">
          <div className="text-center">
            <Grid container spacing={1} paddingX={1}>
              {[...Array(4)].map((_, index) => (
                <Grid item xs={6} lg={3} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={36.5} // Approximate button height
                    width="100%"
                    sx={{ animationDuration: "1s" }}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={76}
            width="100%"
            sx={{ animationDuration: "1s" }}
          />
        </div>
      </div>
    </div>
  )
}
