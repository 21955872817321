// React core imports
import { useState, useEffect, useRef, memo } from "react"

// React Router imports for navigation and URL management
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

// Material-UI core components, sorted alphabetically
import Box from "@mui/material/Box"
import CardHeader from "@mui/material/CardHeader"
import IconButton from "@mui/material/IconButton"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"

// Material-UI icons, sorted alphabetically
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import PhoneIcon from "@mui/icons-material/Phone"
import UpdateIcon from "@mui/icons-material/Update"

// Project-specific styled components
import {
  CardContentStyled,
  CardStyled,
  ListStyled,
  ListItemStyled,
  ListItemTextStyled,
} from "../../styles/TerminalsCardStyles"

// Custom project components
import { ListCheckCircleIcon } from "../ListCheckCircleIcon"
import { SelectableTypography } from "../SelectableTypography"
import { StatusCircleIcon } from "../StatusCircleIcon"
import { TerminalIcon } from "../TerminalRoleIcon"
import { ThreeSixtyMenu } from "../ThreeSixtyMenu"

// Type imports for TypeScript
import type { ITerminal, IBusiness, ILocations } from "./TerminalsTypesFlattened"
import type { IBusinessInfo } from "../../types/businessDetailsTypes"

// Utility functions
import { formatDate } from "../../utils/formatDate"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"
import { getOptions } from "../../data/menuOptions"
import { getSubstringBeforeColon } from "../../utils/getSubstringBeforeColon"

// Assets
import terminalIconGeneric from "../../assets/terminals/terminal-icon.svg"
import { getAddressString } from "../../utils/getAddressString"

interface TerminalsItemProps {
  business: IBusiness
  selectedId?: string
}

const TerminalsItemComponent = ({ business, selectedId }: TerminalsItemProps) => {
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({})

  const [selectedStore, setSelectedStore] = useState<ILocations | null>(null)
  const [selectedTerminalName360, setSelectedTerminalName360] = useState<string | null>(null)
  const [selectedTerminalId360, setSelectedTerminalId360] = useState<string | null>(null)
  const businessId360 = useRef<string>("")
  const locationId360 = useRef<string>("")
  const locationName360 = useRef<string>("")

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const location = useLocation()

  const handleBussinesClick = (event: React.MouseEvent<HTMLButtonElement>, business: IBusiness) => {
    localStorage.setItem("option", "false")
    businessId360.current = business.businessInfo.businessId
    locationId360.current = business.stores[0].store.id
    locationName360.current = business.stores[0].store.name
    setAnchorEls({ ...anchorEls, [businessId360.current]: event.currentTarget })
  }

  const handleStoreClick = (event: React.MouseEvent<HTMLButtonElement>, storeItem: ILocations) => {
    localStorage.setItem("option", "true")
    businessId360.current = storeItem.store.businessId
    locationId360.current = storeItem.store.id
    locationName360.current = storeItem.store.name
    setAnchorEls({ ...anchorEls, [locationId360.current]: event.currentTarget })
  }

  const handleClose = (id: string) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [id]: null,
    }))
  }

  const handleTerminalClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    locationItem: ILocations,
    terminalItem: ITerminal
  ) => {
    localStorage.setItem("option", "true")
    const terminalName = `${terminalItem.name} ${terminalItem.roleDisplayName}`
    setSelectedTerminalName360(terminalName)
    setSelectedTerminalId360(terminalItem.terminalId)
    businessId360.current = locationItem.store.businessId
    locationId360.current = locationItem.store.id
    locationName360.current = locationItem.store.name
    setAnchorEls((prevState) => ({
      ...prevState,
      [terminalName]: event.target as HTMLElement,
    }))
  }

  const handleListItemClick = (storeItem: ILocations) => {
    // Create a new URLSearchParams object with the current search params
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.set("locationId", storeItem.store.id)
    newSearchParams.set("bussinessId", storeItem.store.businessId)
    newSearchParams.set("locationName", storeItem.store.name)

    // Use the 'replace' method to update the URL without adding to the browser history
    navigate(`?${newSearchParams.toString()}`, { replace: true })

    setSelectedStore(storeItem)
    handleClose(storeItem.store.id)
  }

  const handleCloseBusiness = () => {
    handleClose(business.businessInfo.businessId)
  }
  const businessOptions = getOptions(
    handleCloseBusiness,
    navigate,
    businessId360.current,
    locationId360.current,
    locationName360.current,
    location
  )

  useEffect(() => {
    setSelectedStore(business.stores.find((store) => store.store.id === selectedId) ?? null)
  }, [business.stores, selectedId])

  useEffect(() => {
    const urlLocationId = searchParams.get("locationId")

    if (urlLocationId !== undefined && urlLocationId !== null) {
      const selectedStore360 =
        business.stores.find((store) => store.store.id === urlLocationId) ?? null
      setSelectedStore(selectedStore360)
    }
  }, [searchParams])

  return (
    <CardStyled variant="outlined">
      <CardHeader
        sx={{
          marginX: 0,
          paddingX: 1,
          paddingY: { xs: 0.5, sm: 1 },
          borderRadius: "1px",
          backgroundColor: "#DEE2E6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        title={business.businessInfo.name}
        subheader={
          <Box
            sx={{
              color: "#000c",
              marginTop: 1,
              display: "grid",
              gridTemplateColumns: "18px 1fr",
              rowGap: 0.5,
              columnGap: 1,
              alignItems: "flex-start",
            }}
          >
            <>
              <LocationOnIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {getAddressString(business.businessInfo as IBusinessInfo)}
              </Typography>
            </>
            <>
              <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {business.businessInfo.phone !== ""
                  ? `Phone 1: ${formatPhoneNumber(business.businessInfo.phone)}`
                  : "No primary phone"}
              </Typography>
            </>
            <>
              <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {business.businessInfo.secondaryPhone !== ""
                  ? `Phone 2: ${formatPhoneNumber(business.businessInfo.secondaryPhone)}`
                  : "No secondary phone"}
              </Typography>
            </>
          </Box>
        }
        action={
          <>
            <IconButton
              onClick={(event) => {
                handleBussinesClick(event, business)
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <ThreeSixtyMenu
              id={`business-menu-${business.businessInfo.businessId}`}
              anchorEl={anchorEls[business.businessInfo.businessId]}
              onClose={() => {
                handleClose(business.businessInfo.businessId)
              }}
              options={businessOptions}
            />
          </>
        }
      />

      <Box display="flex" flexDirection="column" height="100%">
        <CardContentStyled>
          <ListStyled>
            {business.stores.map((storeItem: ILocations, index) => {
              const storeOptions = getOptions(
                () => {
                  handleClose(storeItem.store.id)
                },
                navigate,
                businessId360.current,
                locationId360.current,
                locationName360.current,
                location
              )

              return (
                <ListItemStyled key={`store-${storeItem.store.id}-${index}`}>
                  <ListItemButton
                    selected={
                      selectedStore !== null && selectedStore?.store.id === storeItem.store.id
                    }
                    onClick={() => {
                      handleListItemClick(storeItem)
                    }}
                    sx={{ paddingLeft: 0.5, paddingRight: 0 }}
                  >
                    <ListItemIcon sx={{ paddingX: 0, marginX: 0, minWidth: 22 }}>
                      {selectedStore !== null && selectedStore?.store.id === storeItem.store.id && (
                        <ListCheckCircleIcon color="primary" size={18} />
                      )}
                    </ListItemIcon>
                    <ListItemTextStyled
                      primary={
                        <Box display="flex" alignItems="center">
                          <span style={{ marginTop: "-3px" }}>
                            <StatusCircleIcon status={storeItem.store.statusMsg} size={14} />
                          </span>

                          <SelectableTypography
                            selected={
                              selectedStore !== null &&
                              selectedStore?.store.id === storeItem.store.id
                            }
                          >
                            {storeItem.store.id}
                          </SelectableTypography>
                        </Box>
                      }
                    />
                    <ListItemTextStyled
                      primary={
                        <SelectableTypography
                          selected={
                            selectedStore !== null && selectedStore?.store.id === storeItem.store.id
                          }
                        >
                          {storeItem.store.sofwareName}
                        </SelectableTypography>
                      }
                    />
                    <ListItemTextStyled
                      primary={
                        <SelectableTypography
                          selected={
                            selectedStore !== null && selectedStore?.store.id === storeItem.store.id
                          }
                        >
                          <div
                            style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
                          >
                            <Tooltip placement="left" title={"Terminals"}>
                              <img
                                src={terminalIconGeneric}
                                alt="terminal icon"
                                style={{ marginRight: "10px" }}
                              />
                            </Tooltip>
                            {storeItem.store.terminals.length}
                          </div>
                        </SelectableTypography>
                      }
                    />
                  </ListItemButton>
                  <IconButton
                    onClick={(event) => {
                      handleStoreClick(event, storeItem)
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <ThreeSixtyMenu
                    id={`store-menu-${storeItem.store.id}`}
                    anchorEl={anchorEls[storeItem.store.id]}
                    onClose={() => {
                      handleClose(storeItem.store.id)
                    }}
                    options={storeOptions}
                  />
                </ListItemStyled>
              )
            })}
          </ListStyled>
          {selectedStore?.store.terminals.map((terminalItem, index) => {
            const handleCloseTerminal = () => {
              if (terminalItem.terminalId !== null && terminalItem.terminalId !== undefined) {
                handleClose(terminalItem.terminalId)
              }
            }
            const terminalOptions = getOptions(
              handleCloseTerminal,
              navigate,
              businessId360.current,
              locationId360.current,
              locationName360.current,
              location,
              null,
              terminalItem.terminalId
            )
            return (
              <CardStyled
                sx={{ marginTop: 0 }}
                variant="outlined"
                key={`terminal-${index}-${terminalItem.terminalId}`}
              >
                <CardHeader
                  sx={{
                    marginX: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    paddingX: 1,
                    paddingY: { xs: 0.5, sm: 1 },
                    borderRadius: "1px",
                    backgroundColor: terminalItem.terminalAlert ? "#e5b8bb" : "#F8F9FA",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  title={
                    <Box
                      display="grid"
                      gridTemplateColumns="auto 1fr 1fr"
                      gridTemplateRows="auto auto auto"
                    >
                      <TerminalIcon
                        roleDisplayName={terminalItem.roleDisplayName}
                        width={24}
                        height={24}
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        className="col-start-2"
                        style={{ textTransform: "capitalize", fontWeight: "bold" }}
                      >
                        {terminalItem.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        className="col-start-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {getSubstringBeforeColon(terminalItem.roleDisplayName)}: {terminalItem.IP}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        className="col-start-2"
                        style={{ textTransform: "lowercase" }}
                      >
                        Status: {terminalItem.status}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        className="row-start-2 col-start-3 text-right"
                        style={{ textTransform: "capitalize" }}
                      >
                        <UpdateIcon sx={{ marginRight: 0.5, fontSize: 14, marginBottom: 0.5 }} />{" "}
                        {formatDate(terminalItem.lastUpdated)}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        className="row-start-3 col-start-3 text-right"
                        style={{ textTransform: "capitalize" }}
                      >
                        {terminalItem?.connectTime !== undefined &&
                          terminalItem?.connectTime !== "" && (
                            <>
                              {terminalItem.status === "online" ? (
                                <ArrowDropUpIcon
                                  sx={{ marginRight: 0.5, fontSize: 14, marginBottom: 0.5 }}
                                />
                              ) : (
                                <ArrowDropDownIcon
                                  sx={{ marginRight: 0.5, fontSize: 14, marginBottom: 0.5 }}
                                />
                              )}
                              {terminalItem?.connectTime.replace("-", "")}
                            </>
                          )}
                      </Typography>
                    </Box>
                  }
                  action={
                    <>
                      <IconButton
                        onClick={(event) => {
                          handleTerminalClick(event, selectedStore, terminalItem)
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <ThreeSixtyMenu
                        id={`terminal-menu-${terminalItem.terminalId}`}
                        anchorEl={anchorEls[terminalItem.terminalId]}
                        onClose={() => {
                          if (
                            terminalItem.terminalId !== null &&
                            terminalItem.terminalId !== undefined
                          ) {
                            handleClose(terminalItem.terminalId)
                          }
                        }}
                        options={terminalOptions}
                      />
                    </>
                  }
                />
              </CardStyled>
            )
          })}
          <ThreeSixtyMenu
            id="business-menu"
            anchorEl={
              selectedTerminalName360 !== null && selectedTerminalName360 !== undefined
                ? anchorEls[selectedTerminalName360]
                : null
            }
            onClose={() => {
              if (selectedTerminalName360 !== null && selectedTerminalName360 !== undefined) {
                handleClose(selectedTerminalName360)
              }
            }}
            options={getOptions(
              () => {
                if (selectedTerminalName360 !== null && selectedTerminalName360 !== undefined) {
                  handleClose(selectedTerminalName360)
                }
              },
              navigate,
              businessId360.current,
              locationId360.current,
              locationName360.current,
              location,
              null,
              selectedTerminalName360,
              selectedTerminalId360
            )}
          />
        </CardContentStyled>
      </Box>
    </CardStyled>
  )
}
export const BusinessCard = memo(TerminalsItemComponent)
