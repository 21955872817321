import { Grid, type SxProps } from "@mui/material"

interface PricingTableGridItemStyledProps {
  children: React.ReactNode
  sx?: SxProps // Add the sx prop here
}

export const PricingTableGridItemStyled = ({ children, sx }: PricingTableGridItemStyledProps) => (
  <Grid
    item
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      height: "2rem",
      paddingY: "0px",
      paddingRight: "4px",
      borderBottom: "0.5px dotted #bbb2a3a1",
      ...sx, // Merge the sx prop with existing styles
    }}
  >
    {children}
  </Grid>
)
