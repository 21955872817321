import { useEffect, useMemo, useRef } from "react"
import { decodeJwtTtl } from "../utils/decodeJwtTtl"
import { useUserContext } from "../context/UserContext"

const INACTIVITY_BUFFER = 720 // Buffer time in seconds (11 minutes of inactivity + 1 minute buffer)

function useAuth() {
  const { refreshTokenHandler } = useUserContext()
  const token = localStorage.getItem("token") ?? ""
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const checkTokenExpiry = async () => {
      if (token !== "") {
        const ttl = decodeJwtTtl(token)

        if (ttl !== null && ttl > 0) {
          // to avoid token expiry when user in inactive(browser closed) when token is about to expire
          // we check if the time left for token expiry is less than Inactivity Buffer we call refresh token handler
          if (ttl < INACTIVITY_BUFFER) {
            try {
              await refreshTokenHandler()
            } catch (error) {
              console.error("Error refreshing token", error)
            }
          } else {
            timeoutIdRef.current = setTimeout(() => {
              void checkTokenExpiry()
            }, (ttl - INACTIVITY_BUFFER) * 1000)
          }
        }
      }
    }

    void checkTokenExpiry()

    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current)
      }
    }
  }, [token])

  return useMemo(() => token !== null && token !== undefined && token !== "", [token])
}

export default useAuth
