import type { ITerminalDetailsField, TerminalDetails } from "../types/detailsTypes"

export const terminalDetailsFields: ITerminalDetailsField[] = [
  {
    key: "status",
    label: "Network Status",
    errorCondition: (item: TerminalDetails) => item.status === "offline",
  },
  { key: "IP", label: "IP Address" },
  {
    key: "generalStatus",
    statusCode: "generalStatus",
    label: "General Status",
    errorCondition: (item: TerminalDetails) => item.generalStatus !== 0,
    errorMessageKey: "generalStatusText",
  },
  {
    key: "billValidator",
    statusCode: "billValidatorStatus",
    label: "Bill Validator",
    errorCondition: (item: TerminalDetails) => item.billValidatorStatus !== 0,
    errorMessageKey: "billValidatorText",
  },
  {
    key: "voucherPrinter",
    statusCode: "billValidatorStatus",
    label: "Printer",
    errorCondition: (item: TerminalDetails) => item.voucherPrinterStatus !== 0,
    errorMessageKey: "voucherPrinterText",
  },
  {
    key: "ticketPrinter",
    statusCode: "billValidatorStatus",
    label: "Ticket Printer",
    errorCondition: (item: TerminalDetails) => item.ticketPrinterStatus !== 0,
    errorMessageKey: "ticketPrinterText",
  },
  { key: "ioType", label: "I/O Type" },
  { key: "manufacture", label: "PC Manufacturer" },
  { key: "model", label: "PC Model" },
  { key: "CPU", label: "CPU" },
  { key: "RAM", label: "RAM" },
  { key: "displayAdapter", label: "Display Adapter" },
  { key: "displayRAM", label: "Display RAM" },
  { key: "displayResolution1", label: "Display Resolution 1" },
  { key: "displayResolution2", label: "Display Resolution 2" },
  { key: "soundDevice", label: "Sound Device" },
  { key: "totalStorage", label: "Total Storage" },
  { key: "freeStorage", label: "Free Storage" },
  { key: "databaseUsage", label: "Database Usage" },
  { key: "OS", label: "Operating System" },
  { key: "imageVersion", label: "Image Version" },
  { key: "statusDate", label: "Status Date" },
  { key: "bootDate", label: "Boot Date" },
  { key: "registrationDate", label: "Registration Date" },
  { key: "timeZone", label: "Time Zone" },
]
