import React from "react"
import { AccordionCashItem } from "./AccordionCashItem"
import { Alert } from "@mui/material"
import { type Business } from "../../../types/ICashAdjustments"

interface Props {
  report: Business[]
}

const Report = ({ report }: Props) => {
  console.log("Report rendered", report)

  const collapseAllBusinesses = report.length === 1
  if (report.length === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <div className="shadow-lg">
      {report.map(
        ({
          businessId,
          businessName,
          totalBusinessAdd,
          totalBusinessRemove,
          totalBusinessStack,
          locations,
        }) => {
          return (
            <React.Fragment key={businessId}>
              <AccordionCashItem
                collapseAllBusinesses={collapseAllBusinesses}
                businessId={businessId}
                businessName={businessName}
                totalBusinessAdd={totalBusinessAdd}
                totalBusinessRemove={totalBusinessRemove}
                totalBusinessStack={totalBusinessStack}
                locations={locations}
              />
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}

export default React.memo(Report)
