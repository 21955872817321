import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { type FilterNameType, type Filters } from "../redux/types"
import { type ChangeFilterType } from "../types/reportTypes"

interface Props {
  filters: Filters
  changeFilter: ChangeFilterType
  filter: FilterNameType
}

export function Filter(props: Props) {
  return (
    <FormGroup>
      {Object.keys(props.filters).map((key) => {
        const filterType = key
        return (
          <FormControlLabel
            key={filterType}
            control={
              <Checkbox
                onChange={props.changeFilter(filterType, props.filter)}
                checked={props.filters[filterType]}
              />
            }
            label={filterType}
          />
        )
      })}
    </FormGroup>
  )
}
