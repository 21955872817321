import React from "react"
import ToggleButton from "@mui/material/ToggleButton/ToggleButton"
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import Typography from "@mui/material/Typography"

interface ExpandCollapseAllProps {
  expandAll: boolean | undefined
  toggleExpandAll: (() => void) | undefined
}

export const ExpandCollapseAll: React.FC<ExpandCollapseAllProps> = ({
  expandAll,
  toggleExpandAll,
}) => (
  <ToggleButton value="check" selected={expandAll} onChange={toggleExpandAll}>
    {expandAll !== undefined && expandAll ? (
      <>
        <UnfoldLessIcon titleAccess="Collapse All" />
        <Typography sx={{ textTransform: "none", fontWeight: "bold" }}>Collapse All</Typography>
      </>
    ) : (
      <>
        <UnfoldMoreIcon titleAccess="Expand All" />
        <Typography sx={{ textTransform: "none", fontWeight: "bold" }}>Expand All</Typography>
      </>
    )}
  </ToggleButton>
)
