import { ReactComponent as VerticalIcon } from "../assets/terminals/vertical.svg"
import { ReactComponent as HorizontalDualScreen } from "../assets/terminals/HorizontalDualScreen.svg"
import { ReactComponent as HorizontalSingleScreen } from "../assets/terminals/HorizontalSingleScreen.svg"
import { ReactComponent as Progressive } from "../assets/terminals/progressiveDisplay.svg"
import { ReactComponent as RedemptionKiosk } from "../assets/terminals/redemtionKiosk.svg"
import { ReactComponent as Pos } from "../assets/terminals/pos.svg"
import { ReactComponent as RechargeStation } from "../assets/terminals/rechargeStation.svg"
import { ReactComponent as Server } from "../assets/terminals/server.svg"
import { ReactComponent as TerminaDefaultlIcon } from "../assets/terminals/terminal-icon.svg"

interface TerminalIconProps {
  roleDisplayName: string
  width: number
  height: number
}

export const TerminalIcon = ({ roleDisplayName, width, height }: TerminalIconProps) => {
  const getRoleIcon = () => {
    const style = {
      width: `${width}px`,
      height: `${height}px`,
      marginTop: "2px",
      marginRight: "2px",
    }

    switch (roleDisplayName) {
      case "Game Terminal: Vertical":
        return <VerticalIcon style={style} />
      case "v":
        return <VerticalIcon style={style} />
      case "Game Terminal: Horizontal Dual Screen":
        return <HorizontalDualScreen style={style} />
      case "ds":
        return <HorizontalDualScreen style={style} />
      case "Game Terminal: Horizontal Single Screen":
        return <HorizontalSingleScreen style={style} />
      case "ss":
        return <HorizontalSingleScreen style={style} />
      case "Progressive Display":
        return <Progressive style={style} />
      case "Redemption Kiosk":
        return <RedemptionKiosk style={style} />
      case "POS":
        return <Pos style={style} />
      case "Recharge Station":
        return <RechargeStation style={style} />
      case "Server":
        return <Server style={style} />

      default:
        return <TerminaDefaultlIcon style={style} />
    }
  }

  return getRoleIcon()
}
