import { Skeleton } from "@mui/material"

export const PeriodSkeleton = () => (
  <div style={{ width: "100%", padding: "0px", boxSizing: "border-box" }}>
    <Skeleton variant="rectangular" height={56} style={{ marginBottom: "16px" }} />
    <Skeleton variant="rectangular" height={36} style={{ marginBottom: "10px" }} />
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
      <Skeleton variant="text" width="20%" style={{ padding: "6px 16px" }} />
    </div>
  </div>
)
